import { ReactNode } from 'react';

import styles from 'components/Button/IconButtonWithTooltip.module.scss';

interface IconButtonWithTooltipProps {
  onClick?: () => void;
  tooltipMessage?: string;
  children?: ReactNode;
}

const IconButtonWithTooltip = ({
  onClick,
  tooltipMessage,
  children,
}: IconButtonWithTooltipProps) => {
  return (
    <div className={styles.root}>
      <button onClick={onClick} className={styles.wrap}>
        {children}
      </button>
      {tooltipMessage && (
        <div className={styles.messageBox}>
          <span>{tooltipMessage}</span>
        </div>
      )}
    </div>
  );
};

export default IconButtonWithTooltip;
