import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import api from 'features/api/chart/backtesting';
import { ReadBacktestingTaskResponse } from 'features/api/chart/schemas';
import { BacktestingSettingsState, StrategyState } from 'features/slices';

export interface BacktestTask {
  taskId: string | null;
  totalAmount: string;
  slippage: string;
  name: string;
  day: string[];
  dayGaps: [number, number, number];
  time: string[];
  markets: string[];
  buyStrategy: string | undefined;
  totalRor: string | undefined;
}

export type BacktestTaskType = BacktestTask & {
  marketsCount: number;
  isTransactionNotOccurred: boolean;
};
interface BacktestState {
  task: BacktestTask | null;
  loading: boolean;
  error: string | null;
  validationError: boolean;
  requestId: string | null;
  done: boolean;
  backtestingId: string | null;
}

export const initialBacktesting: BacktestState = {
  task: null,
  loading: false,
  error: null,
  validationError: false,
  requestId: null,
  done: false,
  backtestingId: null,
};

const _initiateBacktest: CaseReducer<
  BacktestState,
  PayloadAction<[string, BacktestingSettingsState & StrategyState]>
> = (state, action) => {
  const [taskId, basicAndStrategyState] = action.payload;
  state.task = {
    taskId: taskId,
    totalAmount: basicAndStrategyState.totalAmount,
    slippage: basicAndStrategyState.slippage,
    name: basicAndStrategyState.name,
    day: basicAndStrategyState.day,
    dayGaps: basicAndStrategyState.dayGaps,
    time: basicAndStrategyState.time,
    markets: basicAndStrategyState.markets.map((market) => market.name),
    buyStrategy:
      basicAndStrategyState.conditionExpression.buyStrategy?.toString(),
    totalRor: undefined,
  };
};

const _cancelBacktest: CaseReducer<BacktestState> = (state) => {
  Object.assign(state, initialBacktesting);
};

const _setTotalRor: CaseReducer<BacktestState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.task = Object.assign({}, state.task, { totalRor: action.payload });
};

const _setBacktestingId: CaseReducer<BacktestState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.backtestingId = action.payload;
};

export const backtestSlice = createSlice({
  name: 'backtest',
  initialState: initialBacktesting,
  reducers: {
    initiateBacktest: _initiateBacktest,
    cancelBacktest: _cancelBacktest,
    setTotalRor: _setTotalRor,
    setBacktestingId: _setBacktestingId,
  },
  extraReducers: (builder) => {
    builder
      .addCase(startBacktestThunk.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.validationError = false;
        state.requestId = action.meta.arg;
        state.done = false;
      })
      .addCase(startBacktestThunk.fulfilled, (state) => {
        state.error = null;
        state.validationError = false;
        state.loading = false;
        state.done = true;
      })
      .addCase(startBacktestThunk.rejected, (state) => {
        state.task = null;
        state.validationError = true;
        state.error = 'Unknown error';
        state.loading = false;
        state.requestId = null;
        state.done = false;
      });
  },
});

interface BacktestError {
  validationError: boolean;
  errorMessage: string;
}

export const startBacktestThunk = createAsyncThunk<
  ReadBacktestingTaskResponse,
  string,
  {
    rejectValue: BacktestError;
  }
>('backtest/startBacktest', async (taskId: string) => {
  return await api.readBacktestingTask(taskId);
});

export const {
  initiateBacktest,
  cancelBacktest,
  setTotalRor,
  setBacktestingId,
} = backtestSlice.actions;
