import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { SimpleModalProps } from 'components/Modal/ModalTypes';

const ConfirmStrategyModal = ({ onSubmit, onClose }: SimpleModalProps) => {
  return (
    <ConfirmModal
      onSubmit={onSubmit}
      onClose={onClose}
      title={'전략 초기화'}
      buttonText={'초기화'}
    >
      <p>전략을 초기화하시겠습니까?</p>
      <p>초기화하시면 모든 작성 내용이 사라집니다.</p>
    </ConfirmModal>
  );
};

export default ConfirmStrategyModal;
