export enum ModalKind {
  ConfirmFormulaModal,
  ConfirmStrategyModal,
  ConfirmStartBacktestingModal,
  ConfirmCancelBacktestingModal,
  ConfirmFailBacktestingModal,
  ConfirmNoticeModal,
  SelectIndicatorModal,
  SelectMarketModal,
  ImportStrategyModal,
  ImportBoardStrategyModal,
  SaveStrategyModal,
  FollowStrategyModal,
  UnFollowStrategyModal,
  DeleteStrategyFromMarketplaceModal,
  ConfirmStopTradingBotModal,
  ConfirmStartTradingBotModal,
  ImportRobotStrategyModal,
  ConfirmAgreeTermModal,
  PreviewInProgressModal,
  WithdrawlModal,
  ConfirmStrategyImportModal,
}

export type ModalProps = {
  kind: ModalKind;
};

export type SimpleModalProps = {
  onClose: () => void;
  onSubmit: (...args: any[]) => void;
} & ModalProps;

export type SelectValueModalProps<T> = {
  onSelect: (selected: T) => void;
} & SimpleModalProps;
