import { TradingViewCandle } from 'features/api/chart/schemas';

export interface CachedCandleData {
  oldestTimestamp: number; // 캐싱된 데이터 중 가장 오래된 candle의 timestamp
  latestTimestamp: number; // 캐싱된 데이터 중 가장 최신 candle의 timestamp
  candles: { [key: number]: TradingViewCandle };
}

interface CandleRequestRecord {
  latestRequestedTimestamp: number; // 요청된 candle 중 가장 최신의 timestamp
  timeoutRef: NodeJS.Timeout | null;
}

export const keyToLastCandleRequestRecord: {
  [key: string]: CandleRequestRecord;
} = {};

export const lastRequestedKey: { key: string | null } = {
  key: null,
};
