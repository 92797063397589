/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-key */
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useState, useEffect, PropsWithChildren } from 'react';

import * as Sentry from '@sentry/nextjs';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { routes } from 'constants/';
import { useResponsive } from 'hooks';
import { noAuthPages } from 'middleware';
import { Session } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';
import { Provider } from 'react-redux';

import { store } from 'store';

import { queryClient } from 'hooks/useQuery/base';

import { initAmplitude } from 'features/amplitude';

import ErrorBoundary from 'components/ErrorBoundary';
import { UnSupportedLayout } from 'components/Layouts/ResponsiveLayout';
import MultiProvider from 'components/MultiProvider';

import 'styles/index.scss';
import PullToRefresh from 'components/PullToRefresh';

const pageTitlesByPath: { [key: string]: string } = {
  '/': '트레이딩뱅크 | 퀀트 트레이딩 플랫폼',
  '/backtesting': '전략 생성 | 트레이딩뱅크',
  '/marketplace': '전략 마켓 | 트레이딩뱅크',
  '/blog': '블로그 | 트레이딩뱅크',
  '/backtesting/loading': '백테스팅 진행중 | 트레이딩뱅크',
  '/backtesting/result': '백테스팅 결과 | 트레이딩뱅크',
  '/user/[username]/strategy': '내 전략 | 트레이딩뱅크',
  '/user/[username]/tradingrobot': '내 로봇 | 트레이딩뱅크',
  '/signin': '로그인 | 트레이딩뱅크',
  '/signup': '회원가입 | 트레이딩뱅크',
  '/policy/terms': '이용약관 | 트레이딩뱅크',
  '/policy/privacy': '개인정보정책 | 트레이딩뱅크',
};

const exceptionPaths = ['/callback'];
const mobileSupportedPaths = [
  routes.rootRoute,
  routes.signInRoute,
  routes.signUpRoute,
  routes.homeRoute,
  routes.termsRoute,
  routes.privacyRoute,
  routes.blogRoute,
  '/user/[username]',
];
const responsiveSupportedPaths = [...exceptionPaths, ...mobileSupportedPaths];

const PushNotificationLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: session } = useSession();

  useEffect(() => {
    // Function to handle messages from Flutter
    window.FromFlutter = (message) => {
      console.log('Message from Flutter:', message);
      // Do something with the message
      // alert(`Message from Flutter: ${message}`);
    };

    // Function to send messages to Flutter
    const sendMessageToFlutter = () => {
      if (window.flutter_inappwebview?.callHandler) {
        window.flutter_inappwebview.callHandler(
          'ToFlutter',
          'Hello from Next.js',
        );

        if (session) {
          window.flutter_inappwebview.callHandler(
            'ToFlutter',
            `username: ${session.username} / email: ${session.user?.email}`,
          );
        }
        return;
      }

      if (window.flutter_inappwebview?._callHandler) {
        window.flutter_inappwebview._callHandler(
          'ToFlutter',
          'Hello from Next.js',
        );

        if (session) {
          window.flutter_inappwebview._callHandler(
            'ToFlutter',
            `username: ${session.username} / email: ${session.user?.email}`,
          );
        }
        return;
      }

      Sentry.captureMessage('Flutter InAppWebView handler is not available');
      console.log('Flutter InAppWebView handler is not available');
    };

    // Example of sending a message to Flutter
    sendMessageToFlutter();
  }, [session]);

  return <>{children}</>;
};

const AuthPageRedirectLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { status, data: session } = useSession();

  useEffect(() => {
    if (session) {
      Sentry.setUser({
        username: session.username,
        email: session.user?.email || 'email null or undefined',
      });
    }
  }, [session]);

  if (noAuthPages.includes(router.pathname)) {
    return <>{children}</>;
  }

  if (status === 'unauthenticated') {
    if (router.pathname === routes.homeRoute) {
      router.push(routes.rootRoute);
      return null;
    }

    router.push(routes.signInRoute);
    return null;
  }

  if (status === 'authenticated') {
    return <>{children}</>;
  }

  return null;
};

const ResponsiveLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { pathname } = router;

  return (
    <>
      {responsiveSupportedPaths.some((path) => pathname.includes(path)) ? (
        children
      ) : (
        <UnSupportedLayout>{children}</UnSupportedLayout>
      )}
    </>
  );
};

initAmplitude();

const App = ({
  Component,
  pageProps,
}: AppProps<{
  session: Session;
}>) => {
  const [isHydrated, setIsHydrated] = useState(false);
  const router = useRouter();
  const { pathname } = router;
  const { isMobile } = useResponsive();

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (!isHydrated) {
    return (
      <Head>
        <title>{pageTitlesByPath[pathname] || '트레이딩뱅크'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
    );
  }

  return (
    <>
      <Head>
        <title>{pageTitlesByPath[pathname] || '트레이딩뱅크'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1"
        />
      </Head>
      <ErrorBoundary isMobile={isMobile}>
        <MultiProvider
          providers={[
            <Provider store={store} children={null} />,
            <SessionProvider session={pageProps.session} children={null} />,
            <QueryClientProvider client={queryClient} />,
            <PushNotificationLayout />,
            <AuthPageRedirectLayout />,
            <ResponsiveLayout />,
          ]}
        >
          <PullToRefresh>
            <Component {...pageProps} />
          </PullToRefresh>
          <ReactQueryDevtools initialIsOpen={false} />
        </MultiProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
