import { validateIndicatorNameToInfo } from 'features/adaptor/validators/';
import { Formula } from 'features/schemas/client/';
import { StrategyExprs } from 'features/schemas/server/';

export const validateExprsToFormulas = (exprs: StrategyExprs) => {
  return exprs.reduce<Formula[]>((acc, expr) => {
    if (expr && typeof expr === 'object') {
      const { name, tokens, kind: candleKind } = expr;
      const tokensWithIndicatorInfo = tokens.map((token) => {
        if (typeof token === 'object') {
          const { name, shift, ...rest } = token;
          return {
            ...rest,
            indicator: validateIndicatorNameToInfo(name),
            shift: shift + 1,
          };
        } else {
          return token;
        }
      });

      acc.push({
        name,
        tokens: tokensWithIndicatorInfo,
        candleKind,
      });
    }
    return acc;
  }, []);
};
