export type PreviewKind = 'buy' | 'sell';

export type CryptoExchangeKind = 'coinone';

export enum CandleKind {
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Min1 = 'min1',
  Min3 = 'min3',
  Min5 = 'min5',
  Min10 = 'min10',
  Min15 = 'min15',
  Min30 = 'min30',
  Min60 = 'min60',
  Min240 = 'min240',
}

export const CandleKindToText = {
  [CandleKind.Min1]: '1분',
  [CandleKind.Min3]: '3분',
  [CandleKind.Min5]: '5분',
  [CandleKind.Min10]: '10분',
  [CandleKind.Min15]: '15분',
  [CandleKind.Min30]: '30분',
  [CandleKind.Min60]: '1시간',
  [CandleKind.Min240]: '4시간',
  [CandleKind.Day]: '일',
  [CandleKind.Week]: '주',
  [CandleKind.Month]: '월',
};

export enum TaskStateKind {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Retry = 'RETRY',
  Revoked = 'REVOKED',
  Started = 'STARTED',
  Success = 'SUCCESS',
  Progress = 'PROGRESS',
}

export enum RelationalStatementKind {
  EQ = 'eq',
  NE = 'ne',
  GT = 'gt',
  GE = 'ge',
  LT = 'lt',
  LE = 'le',
}

export enum RelationalExpressionKind {
  Add = 'add',
  Sub = 'sub',
  Mul = 'mul',
  Div = 'div',
  Unary = 'unary',
}

export enum ConditionExpressionKind {
  And = 1,
  Or = 2,
  Unary = 3,
}

export enum TradingViewCandleKind {
  Month = '1M',
  Week = '1W',
  Day = '1D',
  Min1 = '1',
  Min3 = '3',
  Min5 = '5',
  Min10 = '10',
  Min15 = '15',
  Min30 = '30',
  Min60 = '60',
  Min240 = '240',
}

export const isTaskFinished = (state: TaskStateKind): boolean => {
  return (
    state !== TaskStateKind.Pending &&
    state !== TaskStateKind.Progress &&
    state !== TaskStateKind.Started
  );
};

export const convertToCandleKind = (
  value: TradingViewCandleKind,
): CandleKind => {
  switch (value) {
    case TradingViewCandleKind.Month:
      return CandleKind.Month;
    case TradingViewCandleKind.Week:
      return CandleKind.Week;
    case TradingViewCandleKind.Day:
      return CandleKind.Day;
    case TradingViewCandleKind.Min1:
      return CandleKind.Min1;
    case TradingViewCandleKind.Min3:
      return CandleKind.Min3;
    case TradingViewCandleKind.Min5:
      return CandleKind.Min5;
    case TradingViewCandleKind.Min10:
      return CandleKind.Min10;
    case TradingViewCandleKind.Min15:
      return CandleKind.Min15;
    case TradingViewCandleKind.Min30:
      return CandleKind.Min30;
    case TradingViewCandleKind.Min60:
      return CandleKind.Min60;
    case TradingViewCandleKind.Min240:
      return CandleKind.Min240;
  }
};

export const convertToIntervalInMilliseconds = (
  value: TradingViewCandleKind,
): number => {
  switch (value) {
    case TradingViewCandleKind.Month:
      return 30 * 24 * 60 * 60 * 1000;
    case TradingViewCandleKind.Week:
      return 7 * 24 * 60 * 60 * 1000;
    case TradingViewCandleKind.Day:
      return 24 * 60 * 60 * 1000;
    case TradingViewCandleKind.Min1:
      return 60 * 1000;
    case TradingViewCandleKind.Min3:
      return 3 * 60 * 1000;
    case TradingViewCandleKind.Min5:
      return 5 * 60 * 1000;
    case TradingViewCandleKind.Min10:
      return 10 * 60 * 1000;
    case TradingViewCandleKind.Min15:
      return 15 * 60 * 1000;
    case TradingViewCandleKind.Min30:
      return 30 * 60 * 1000;
    case TradingViewCandleKind.Min60:
      return 60 * 60 * 1000;
    case TradingViewCandleKind.Min240:
      return 240 * 60 * 1000;
  }
};

export const convertToTradingViewCandleKind = (
  value: CandleKind,
): TradingViewCandleKind => {
  switch (value) {
    case CandleKind.Month:
      return TradingViewCandleKind.Month;
    case CandleKind.Week:
      return TradingViewCandleKind.Week;
    case CandleKind.Day:
      return TradingViewCandleKind.Day;
    case CandleKind.Min1:
      return TradingViewCandleKind.Min1;
    case CandleKind.Min3:
      return TradingViewCandleKind.Min3;
    case CandleKind.Min5:
      return TradingViewCandleKind.Min5;
    case CandleKind.Min10:
      return TradingViewCandleKind.Min10;
    case CandleKind.Min15:
      return TradingViewCandleKind.Min15;
    case CandleKind.Min30:
      return TradingViewCandleKind.Min30;
    case CandleKind.Min60:
      return TradingViewCandleKind.Min60;
    case CandleKind.Min240:
      return TradingViewCandleKind.Min240;
  }
};

const marketKrNames = {
  ZETA: '제타체인',
  ALT: '알트레이어',
  JUP: '주피터',
  TOKAMAK: '토카막 네트워크',
  BTC: '비트코인',
  XRP: '리플',
  ETH: '이더리움',
  USDT: '테더 USDT',
  SOL: '솔라나',
  CEP: '시리얼',
  LINK: '체인링크',
  SUI: '수이',
  WEMIX: '위믹스',
  ETC: '이더리움 클래식',
  TIDE: '타이달 플래츠',
  DOGE: '도지코인',
  KLAY: '클레이튼',
  THN: '쓰론',
  TRX: '트론',
  ADA: '에이다',
  MANTA: '만타 네트워크',
  FIL: '파일코인',
  BCH: '비트코인 캐시',
  PYTH: '피스 네트워크',
  EOS: '이오스',
  SEI: '세이 네트워크',
  ONDO: '온도 파이낸스',
  BONK: '봉크',
  IOTA: '아이오타',
  ATOM: '코스모스아톰',
  DOT: '폴카닷',
  IDV: '이다볼 다오',
  MNR: '미네랄',
  RDNT: '라디언트 캐피탈',
  PIB: '피블',
  BWO: '배틀월드',
  KAS: '카스파',
  JTO: '지토',
  SML: '솔트마블',
  WLD: '월드코인',
  WE: '위바이',
  SHIB: '시바이누',
  MATIC: '폴리곤',
  ARB: '아비트럼',
  BTT: '비트토렌트',
  AVAX: '아발란체',
  TIA: '셀레스티아',
  ATT: '아튜브',
  DMAIL: '디메일',
  ORC: '오르빗체인',
  QTCON: '퀴즈톡',
  SAND: '샌드박스',
  INJ: '인젝티브',
  GRND: '슈퍼워크',
  XTZ: '테조스',
  FET: '페치',
  XLM: '스텔라루멘',
  SSX: '썸씽',
  ASM: '어셈블 프로토콜',
  GAL: '갤럭시',
  ICP: '인터넷 컴퓨터',
  QTUM: '퀀텀',
  SEILOR: '크립토나이트',
  NPT: '네오핀',
  FNCY: '팬시',
  HVH: '하바',
  AXS: '엑시 인피니티',
  RNDR: '렌더토큰',
  BSV: '비트코인사토시비전',
  KSP: '클레이스왑',
  BEAM: '빔',
  DYDX: '디와이디엑스',
  ASTR: '아스타 네트워크',
  BMP: '비엠피',
  GALA: '갈라',
  STX: '스택스',
  GAS: '가스',
  HPO: '히포크랏',
  GMT: '스테픈',
  CKB: '너보스',
  ZRX: '제로엑스',
  AI: '슬립리스 에이아이',
  ZTX: '지티엑스',
  PENDLE: '펜들',
  MEGA: '메가 프로토콜',
  NFP: '엔에프 프롬프트',
  BNB: '비앤비',
  FORTH: '앰플포스 거버넌스 토큰',
  FNSA: '핀시아',
  MANA: '디센트럴랜드',
  BLUR: '블러',
  MVC: '마일벌스',
  REAP: '립체인',
  MEME: '밈코인',
  BORA: '보라',
  TON: '톤코인',
  CLBK: '클라우드브릭',
  BAAS: '바스아이디',
  NEO: '네오',
  FLOW: '플로우',
  ACH: '알케미 페이',
  VET: '비체인',
  ZIL: '질리카',
  SXP: '솔라',
  MLK: '밀크',
  XEC: '이캐시',
  AMO: '아모코인',
  T: '쓰레스홀드',
  LBR: '리브라 파이낸스',
  CTSI: '카르테시',
  APT: '앱토스',
  DRC: '디알씨 모빌리티',
  CRO: '크로노스',
  CFX: '콘플럭스',
  PEPE: '페페',
  DODO: '도도',
  SKLAY: '에스클레이',
  VOLT: '볼트이누',
  LZM: '라운지엠',
  BAL: '발란서',
  OP: '옵티미즘',
  APE: '에이프코인',
  AAVE: '에이브',
  CYBER: '사이버커넥트',
  BTG: '비트코인 골드',
  APP: '문앱',
  KSC: '케이스타라이브',
  WBTC: '랩트비트코인',
  DATA: '스트리머',
  AGIX: '싱귤래리티넷',
  MAP: '맵 프로토콜',
  SPA: '스페라엑스',
  BIGTIME: '빅타임',
  ORB: '오브시티',
  DAO: '다오 메이커',
  NEST: '네스트 프로토콜',
  BEL: '벨라 프로토콜',
  MINA: '미나 프로토콜',
  GRT: '더그래프',
  HOT: '홀로',
  ANKR: '앵커 네트워크',
  HIBS: '힙스',
  NTRN: '뉴트론',
  UNI: '유니스왑',
  WAVES: '웨이브',
  DVI: '디비전',
  ILV: '일루비움',
  PRIME: '에셜론 프라임',
  COMP: '컴파운드',
  FTM: '팬텀',
  HBAR: '헤데라',
  NFT: '에이피이앤에프티',
  CVX: '컨벡스 파이낸스',
  BAT: '베이직어텐션토큰',
  UMA: '우마',
  WAXL: '엑셀라',
  WNCG: '랩트 나인 크로니클 골드',
  '1INCH': '1인치',
  ALGO: '알고랜드',
  HFT: '해시플로우',
  NEXT: '커넥스트',
  MAV: '매버릭 프로토콜',
  IMX: '이뮤터블엑스',
  ALPHA: '스텔라',
  STORJ: '스토리지',
  WIKEN: '위드',
  MTL: '메탈',
  BAND: '밴드 프로토콜',
  ORBS: '오브스',
  ABL: '에어블록',
  MBL: '무비블록',
  ARKM: '아캄',
  IQ: '아이큐',
  CELO: '셀로',
  GLM: '골렘',
  RUNE: '토르체인',
  STPT: '에스티피',
  SNX: '신세틱스 네트워크 토큰',
  EDU: '오픈 캠퍼스',
  NEAR: '니어프로토콜',
  TEMCO: '템코',
  OGN: '오리진 프로토콜',
  ONIT: '온버프',
  HIFI: '하이파이',
  COS: '콘텐토스',
  MTA: '메타',
  GET: '겟 프로토콜',
  SNT: '스테이터스네트워크토큰',
  IOST: '아이오에스티',
  EVER: '에버스케일',
  HIGH: '하이스트리트',
  KNC: '카이버',
  MEV: '미버스',
  MKR: '메이커',
  SPURS: '토트넘 홋스퍼',
  FXS: '프랙스 쉐어',
  ARPA: '알파',
  ONG: '온톨로지가스',
  STG: '스타게이트 파이낸스',
  KAVA: '카바',
  VSYS: '브이시스템즈',
  KSM: '쿠사마',
  IOTX: '아이오텍스',
  CRV: '커브',
  SUSHI: '스시',
  ONT: '온톨로지',
  IPX: '타키온 프로토콜',
  AMP: '앰프',
  AUCTION: '바운스 토큰',
  BNT: '방코르',
  CBK: '코박 토큰',
  CLV: '씨엘브이',
  CRU: '크러스트 네트워크',
  DAD: '다드',
  DIA: '디아',
  EGG: '네스트리',
  ERN: '이더니티 체인',
  FIS: '스타파이',
  FLUX: '플럭스',
  FRONT: '프론티어',
  HARD: '하드 프로토콜',
  HUNT: '헌트',
  ICX: '아이콘',
  ISK: '이스크라',
  JST: '저스트',
  KAI: '카르디아체인',
  LBL: '레이블',
  LIT: '릿엔트리',
  LQTY: '리퀴티',
  MASK: '마스크네트워크',
  MNT: '맨틀',
  PAXG: '팍스골드',
  PHA: '팔라 네트워크',
  PROM: '프롬',
  RPL: '로켓풀',
  SIX: '식스',
  SKL: '스케일',
  SUN: '썬',
  TOMOE: '빅션',
  TVK: '테라 버추어',
  TWT: '트러스트 월렛 토큰',
  UOS: '울트라',
};

export const marketKrNameMap = new Map(Object.entries(marketKrNames));
