import { useRouter } from 'next/router';

import { useEffect } from 'react';

import { routes } from 'constants/';
import { useSession } from 'next-auth/react';

export const useSyncUsername = () => {
  const router = useRouter();
  const { username } = router.query;
  const { data: session, status } = useSession();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    if (status === 'unauthenticated') {
      router.push(routes.signInRoute);
    }

    if (status === 'authenticated') {
      if (username !== session.username) {
        router.replace({
          pathname: router.pathname,
          query: { ...router.query, username: session.username },
        });
      }
      session.username;
    }
  }, [router, session, status, username]);
};
