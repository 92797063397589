import { useRouter } from 'next/router';

import { useMutation } from '@tanstack/react-query';

import { routes } from 'constants/';

import { useAppDispatch, useAppSelector } from 'store';

import api from 'features/api/chart/backtesting';
import {
  BacktestingForm,
  BacktestingPreviewForm,
} from 'features/schemas/server/';
import { initiateBacktest, startBacktestThunk } from 'features/slices';

// TODO:
// 이 훅은 먼저 form으 전달하여 taskId를 받아오고, (createBacktestingTask)
// taskId를 전달하여 생성된 백테스팅 결과를 받아오는 역할을 한다. (readBacktestingTask)

// 점진적으로 리액트 쿼리 마이그레이션을 하고 있는데,
// 현재는 리덕스 상태와 연동하여 readBacktestingTask를 호출하는 상황이다.
// 결국은 리덕스 상태는 걷어내고 서버 상태와 직접 연동하는 식으로 리팩토링할 계획이다.

// 아직 과도기라 당장은 해당 훅에서 taskId를 만들고,
// 이후 백테스팅 요청 및 라우팅하는 작업의 로직은 따로 건드리지 않고 onSucccess를 이용하여 단계를 구분만 지어놨다.
const useCreateBacktestingTaskMutation = () => {
  const [settingsState, strategyState] = useAppSelector(
    ({ settings, strategy }) => [settings, strategy],
  );
  const dispatch = useAppDispatch();
  const router = useRouter();

  return useMutation({
    mutationFn: (form: BacktestingForm): Promise<string> =>
      api.createBacktestingTask(form),
    onSuccess: (taskId) => {
      dispatch(
        initiateBacktest([taskId, { ...settingsState, ...strategyState }]),
      );
      dispatch(startBacktestThunk(taskId));
      router.push(routes.backtestLoadingRoute);
    },
  });
};

const useRevokeTaskMutation = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: (taskId: string): Promise<void> => api.revokeTask(taskId),
    onSuccess,
  });
};

const useCreatePreviewTask = ({ form }: { form: BacktestingPreviewForm }) => {
  return useMutation(() => api.createBacktestingPreviewTask(form));
};

export default {
  useCreateBacktestingTaskMutation,
  useRevokeTaskMutation,
  useCreatePreviewTask,
};
