import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { clsx } from 'clsx';
import { MenuItem, routes } from 'constants/';
import { useResponsive } from 'hooks';
import GuideIcon from 'images/icons/svgs/guide.svg';
import HeaderVerticalLine from 'images/icons/svgs/header-vertical-line.svg';
import { signOut, useSession } from 'next-auth/react';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import Button from 'components/Button';
import IconButtonWithTooltip from 'components/Button/IconButtonWithTooltip';
import Logo from 'components/Logo';

import styles from 'components/Layouts/Header/Header.module.scss';

interface HeaderProps {
  items: MenuItem[];
  rightElement?: React.ReactNode;
}
const HeaderRightContents = () => {
  const { data: session, status } = useSession();

  const handleLogoutClick = async () => {
    trackEvent(amplitudeEventNames.BTN_ACCOUNT_SIGNOUT);
    await signOut({ callbackUrl: routes.signInRoute });
  };

  const handleGuideClick = () => {
    trackEvent(amplitudeEventNames.FORMULA_GUIDE);
  };

  const toggleChannelService = () => {
    trackEvent(amplitudeEventNames.H_MENU_CS);
    window.ChannelIO?.('showMessenger');
    // window.open('https://tradingbank.channel.io/', '_blank');
  };

  const handleCommunityClick = () => {
    trackEvent(amplitudeEventNames.H_MENU_COMMUNITY);
  };

  const handleUserProfileClick = () => {
    trackEvent(amplitudeEventNames.H_MENU_MYPAGE);
  };

  if (status !== 'authenticated') {
    return null;
  }

  return (
    <div className={styles.rightContentsContainer}>
      <div className={styles.links}>
        <IconButtonWithTooltip
          tooltipMessage="고객 문의"
          onClick={toggleChannelService}
        >
          <Image
            src="/icons/inquiry-icon.png"
            alt="channeltalk-icon"
            width={24}
            height={24}
          />
        </IconButtonWithTooltip>
        <IconButtonWithTooltip tooltipMessage="가이드 보기">
          <a
            href="https://lavender-scion-447.notion.site/310d32389d1d42e39a88af38c3a6136b"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            onClick={handleGuideClick}
          >
            <GuideIcon viewBox="0 0 334 244" width="24" height="24" />
          </a>
        </IconButtonWithTooltip>
        <IconButtonWithTooltip tooltipMessage="오픈채팅 입장">
          <a
            href="https://open.kakao.com/o/gOdbpJqf"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            onClick={handleCommunityClick}
          >
            <Image
              src="/icons/openchat-icon.png"
              alt="openchat"
              width={24}
              height={24}
            />
          </a>
        </IconButtonWithTooltip>
      </div>
      <HeaderVerticalLine />
      <div className={styles.userInfo}>
        <Link href={routes.myPageRoute(session.username)}>
          <button className={styles.profile} onClick={handleUserProfileClick}>
            <div className={styles.name}>
              <span>{session.username}</span>
              <span>님</span>
            </div>
            <div className={styles.email}>{session.user?.email}</div>
          </button>
        </Link>
        <Button onClick={handleLogoutClick} theme="neutral2" size="small">
          로그아웃
        </Button>
      </div>
    </div>
  );
};

export const Header = ({ rightElement, items }: HeaderProps) => {
  const router = useRouter();
  const { data: session, status } = useSession();
  const { isDesktop } = useResponsive();

  const handleMenuClick = (amplitudeEventName: string | undefined) => {
    if (!amplitudeEventName) {
      return;
    }

    trackEvent(amplitudeEventName);
  };

  const handleLogoClick = () => {
    trackEvent(amplitudeEventNames.H_TB_LOGO);

    if (status === 'authenticated') {
      router.push(routes.homeRoute);
      return;
    }

    router.push(routes.signInRoute);
  };

  return (
    <header className={styles.wrap}>
      <div className={styles.left}>
        <Button onClick={handleLogoClick} theme="none" size="none">
          <Logo />
        </Button>

        <ul className={styles.menu}>
          {items.map(
            ({ name, to, pathPattern, amplitudeEventName = undefined }) => {
              if (typeof to !== 'string') {
                to = to(session?.username || '');
              }

              return (
                <li
                  key={name}
                  className={clsx(styles.menuItem, {
                    [styles.active]:
                      router.pathname === to ||
                      router.pathname.includes(pathPattern),
                  })}
                  onClick={() => handleMenuClick(amplitudeEventName)}
                >
                  <Link href={to}>
                    <a>{name}</a>
                  </Link>
                </li>
              );
            },
          )}
        </ul>
      </div>
      {isDesktop && (
        <div className={styles.right}>
          {rightElement ? rightElement : <HeaderRightContents />}
        </div>
      )}
    </header>
  );
};
