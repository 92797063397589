import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CryptoExchangeKind } from 'constants/';
import { differenceInDays, format, sub, add } from 'date-fns';

import { saveBacktestingToReduxState } from 'features/adaptor/converters/';

import { env } from 'features/env';
import { Market } from 'features/schemas/client';
import { BacktestingWithConfig } from 'features/schemas/server';
import { StrategyState } from 'features/slices';
import {
  checkNotStartingWithZero,
  countTimeGap,
  parseCurrencyInput,
} from 'features/utils';

import {
  validateFormulas,
  validateSellStrategy,
} from 'features/utils/validateStrategy';

export interface BacktestingSettingsState {
  id: string | null;
  name: string;
  exchange: CryptoExchangeKind;
  totalAmount: string;
  slippage: string;
  day: string[];
  dayGaps: [number, number, number];
  time: string[];
  markets: Market[];
  createdAt?: string;
}

const now = new Date();
const today = format(now, 'yyyy-MM-dd');
const oneMonthAgo = format(sub(now, { months: 1 }), 'yyyy-MM-dd');
const initialTotalAmount = 1000;
const maxTotalAmount = 50000;

export const initialBacktestingSetting: () => BacktestingSettingsState = () => {
  const daysDifference = differenceInDays(now, sub(now, { months: 1 }));
  return {
    id: null,
    name: format(new Date(), 'yyyyMMdd_HHmmss'),
    exchange: 'coinone',
    totalAmount: initialTotalAmount.toLocaleString(),
    slippage: '',
    day: [oneMonthAgo, today],
    time: ['00:00', '23:59'],
    dayGaps: [daysDifference, 23, 59],
    markets: [],
    createdAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  };
};

const _importSettings: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<BacktestingWithConfig>
> = (state, action) => {
  const settings = action.payload;
  return saveBacktestingToReduxState(settings);
};

const _setId: CaseReducer<BacktestingSettingsState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.id = action.payload;
};

const _setBacktestingName: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<string>
> = (state, action) => {
  state.name = action.payload;
};

const _setCryptoExchangeKind: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<CryptoExchangeKind>
> = (state, action) => {
  state.exchange = action.payload;
};

const _editTotalAmount: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<[string, string]>
> = (state, action) => {
  const inputValue = action.payload[1].replace(',', '');

  if (!checkNotStartingWithZero(inputValue) || Number(inputValue) < 1) {
    state.totalAmount = '1';
    return;
  }

  if (Number(inputValue) > maxTotalAmount) {
    state.totalAmount = maxTotalAmount.toLocaleString();
    return;
  }

  state.totalAmount = parseCurrencyInput(state.totalAmount, inputValue)!;
};

const _changeDate: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<[number, string]>
> = (state, action) => {
  const endDay = state.day[1];
  const [dateIndex, newDate] = action.payload;
  if (dateIndex === 0) {
    if (newDate > endDay) {
      state.day[dateIndex] = endDay;
    } else {
      if (newDate === endDay && state.time[0] > state.time[1]) {
        state.time[0] = state.time[1];
        state.dayGaps[1] = 0;
        state.dayGaps[2] = 0;
      }
      state.day[dateIndex] = newDate;
    }
  } else {
    if (newDate < state.day[0]) {
      state.day[0] = newDate;
      state.day[1] = newDate;
    } else {
      state.day[dateIndex] = newDate;
    }
  }

  const difference = Math.abs(
    differenceInDays(new Date(state.day[0]), new Date(state.day[1])),
  );

  const BACKTESTING_PERIOD_LIMIT = 365 * 5;

  if (env.STAGE !== 'dev' && difference > BACKTESTING_PERIOD_LIMIT) {
    // MEMO: TB-4718, limit period under 5 years in production
    const oneYearFromEnd = format(
      sub(new Date(state.day[1]), { years: 5 }),
      'yyyy-MM-dd',
    );

    const oneYearFromStart = format(
      add(new Date(state.day[0]), { years: 5 }),
      'yyyy-MM-dd',
    );
    dateIndex === 0
      ? (state.day[dateIndex] = oneYearFromEnd)
      : (state.day[dateIndex] = oneYearFromStart);
    state.dayGaps[0] = BACKTESTING_PERIOD_LIMIT;
  } else {
    state.dayGaps[0] = difference;
  }
};

const _changeTime: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<[number, string]>
> = (state, action) => {
  const [timeIndex, newTime] = action.payload;

  const startTime = state.time[0].split(':').map((time) => parseInt(time));

  const endTime = state.time[1].split(':').map((time) => parseInt(time));

  const updatedTime = newTime.split(':').map((time) => parseInt(time));

  const hourGap =
    timeIndex === 0
      ? updatedTime[0] - endTime[0]
      : startTime[0] - updatedTime[0];

  const minuteGap =
    timeIndex === 0
      ? updatedTime[1] - endTime[1]
      : startTime[1] - updatedTime[1];

  const [calculatedHour, calculatedMinute] = countTimeGap(hourGap, minuteGap);

  const sameDayBiggerStart =
    state.day[0] === state.day[1] &&
    (hourGap > 0 || (hourGap === 0 && minuteGap > 0));

  if (!sameDayBiggerStart) {
    state.time[timeIndex] = newTime;
    state.dayGaps[1] = calculatedHour;
    state.dayGaps[2] = calculatedMinute;
  } else {
    state.time[0] = newTime;
    state.time[1] = newTime;
    state.dayGaps[1] = 0;
    state.dayGaps[2] = 0;
  }
};

const _selectMarkets: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<Market[]>
> = (state, action) => {
  state.markets = action.payload;
};

const _setBacktestingSettingInitialState: CaseReducer<
  BacktestingSettingsState,
  PayloadAction<BacktestingSettingsState>
> = (state, action) => {
  const data = action.payload;
  return { ...data };
};

export const backtestingSettingsSlice = createSlice({
  name: 'basic',
  initialState: initialBacktestingSetting(),
  reducers: {
    setId: _setId,
    setBacktestingName: _setBacktestingName,
    setCryptoExchangeKind: _setCryptoExchangeKind,
    editTotalAmount: _editTotalAmount,
    changeDate: _changeDate,
    changeTime: _changeTime,
    selectMarkets: _selectMarkets,
    resetSettings: initialBacktestingSetting,
    importSettings: _importSettings,
    setBacktestingSettingInitialState: _setBacktestingSettingInitialState,
  },
});

export const {
  setId,
  setBacktestingName,
  setCryptoExchangeKind,
  editTotalAmount,
  changeDate,
  changeTime,
  selectMarkets,
  resetSettings,
  importSettings,
  setBacktestingSettingInitialState,
} = backtestingSettingsSlice.actions;

export const getValidationState = (
  settingsState: BacktestingSettingsState,
  strategyState: StrategyState,
): Record<string, boolean> => {
  const isBasicSettingValid =
    Boolean(settingsState.totalAmount) && settingsState.day.length === 2;

  const isMarketSettingValid = settingsState.markets.length > 0;

  const isBuyStrategySettingValid =
    strategyState.conditionExpression.buyStrategy.length > 0 &&
    validateFormulas(strategyState.buyFormulas) &&
    strategyState.buyFormulas.length > 0 &&
    strategyState.buyFormulas[0].tokens.length > 0;

  const isSellStrategySettingValid =
    validateSellStrategy(strategyState.sellConfig) &&
    (strategyState.sellFormulas.length === 0 ||
      validateFormulas(strategyState.sellFormulas));

  return {
    basic: isBasicSettingValid,
    market: isMarketSettingValid,
    buyStrategy: isBuyStrategySettingValid,
    sellStrategy: isSellStrategySettingValid,
  };
};
