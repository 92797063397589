import clsx from 'clsx';

import { Market } from 'features/api/chart/schemas';
import { formatCurrency } from 'features/utils';

import Button from 'components/Button';

import SortIcon, { SortOrder } from 'components/Icon/SortIcon';

import Radio from 'components/Input/Radio';

import styles from 'components/Row/SelectMarketModalRow.module.scss';

interface SelectMarketModalHeaderRowProps {
  transactionAmountSortOrder: SortOrder;
  handleTransactionAmountSortOrderToggle: () => void;
}

export const SelectMarketModalHeaderRow = ({
  transactionAmountSortOrder,
  handleTransactionAmountSortOrderToggle,
}: SelectMarketModalHeaderRowProps) => {
  return (
    <div className={clsx(styles.root, styles.headerRoot)}>
      <div className={styles.selectColumn}></div>
      <div className={styles.marketColumn}>
        <span className={styles.rowText}>종목명</span>
      </div>
      <div className={styles.amountColumn}>
        <Button
          onClick={handleTransactionAmountSortOrderToggle}
          isFullWidth
          theme="none"
          size="none"
        >
          <span>거래대금</span>
          <SortIcon sortOrder={transactionAmountSortOrder} />
        </Button>
      </div>
    </div>
  );
};

interface SelectMarketModalMarketRowProps {
  market: Market;
  isSelected: boolean;
  handleSelect: () => void;
  searchText: string;
}

export const SelectMarketModalMarketRow = ({
  market,
  isSelected,
  handleSelect,
  searchText,
}: SelectMarketModalMarketRowProps) => {
  const transactionAmountString = formatCurrency(
    Math.floor(market.dollar_volume / 1000000),
  );
  const isLowVolume = market.dollar_volume < 1000000000;

  const splitMatchedText = (text: string) => {
    return text.split(new RegExp(`(${searchText})`, 'gi'));
  };

  const highlightText = (text: string) => {
    if (!text.toLowerCase().includes(searchText.toLowerCase())) {
      return text;
    }

    return splitMatchedText(text).map((substring, index) => {
      if (substring.toLowerCase() !== searchText.toLowerCase()) {
        return substring;
      }

      return (
        <span key={`${substring}-${index}`} className={styles.highlight}>
          {substring}
        </span>
      );
    });
  };

  return (
    <div className={clsx(styles.root, isLowVolume && styles.lowVolume)}>
      <div className={styles.selectColumn}>
        {!isLowVolume && (
          <Radio
            isSelected={isSelected}
            onChange={handleSelect}
            title={market.name.split('-')[1]}
            isLabelVisible={false}
          />
        )}
      </div>
      <div className={styles.marketColumn}>
        <span className={styles.rowText}>
          {highlightText(market.name.split('-')[1])}

          <span className={styles.grayText}>
            {highlightText(market.kr_name!)}
          </span>
        </span>
      </div>
      {
        <div className={styles.amountColumn}>
          <span className={styles.rowText}>{transactionAmountString} 백만</span>
        </div>
      }
    </div>
  );
};
