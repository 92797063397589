import clsx from 'clsx';

import { useSession } from 'next-auth/react';

import { BacktestingWithConfig } from 'features/schemas/server';
import {
  convertUTCDateStringToKST,
  decimalFormatter,
  splitDateTimeOfISOString,
} from 'features/utils';

import TextHighlight from 'components/Textbox/TextHighlight';

import styles from 'components/Modal/ImportStrategyModal/ImportStrategyModalRow.module.scss';

export const ImportStrategyModalHeaderRow = () => {
  return (
    <div className={clsx(styles.headerRoot)}>
      <div className={styles.createdAtColumn}>
        <span>작성 일자</span>
      </div>
      <div className={styles.titleColumn}>
        <span>전략 이름</span>
      </div>
      <div className={styles.rorColumn}>
        <span>총 수익률</span>
      </div>
      <div className={styles.mddColumn}>
        <span>MDD</span>
      </div>
      <div className={styles.backtestingRangeColumn}>
        <span>백테스팅 기간</span>
      </div>
    </div>
  );
};

interface ImportStrategyModalMarketRowProps {
  backtesting: BacktestingWithConfig;
  handleSelect: () => void;
}

export const ImportStrategyModalMarketRow = ({
  backtesting,
  handleSelect,
}: ImportStrategyModalMarketRowProps) => {
  const { data: session } = useSession();
  const { date: createdAtDate, time: createdAtTime } = splitDateTimeOfISOString(
    convertUTCDateStringToKST(backtesting.created_at),
  );

  const { date: backtestingStartDate } = splitDateTimeOfISOString(
    backtesting.config.start,
  );

  const { date: backtestingEndDate } = splitDateTimeOfISOString(
    backtesting.config.end,
  );

  const hasBacktestingResult = Boolean(backtesting.ror);

  return (
    <div className={styles.root} onClick={handleSelect}>
      <div className={styles.createdAtRow}>
        <div className={styles.createdAtWrapper}>
          <span>{createdAtDate}</span>
          <span>{createdAtTime}</span>
        </div>
      </div>
      <div className={styles.titleRow}>
        <div className={styles.titleWrapper}>
          <span>{backtesting.name}</span>
          <TextHighlight
            text={
              backtesting.author === session?.username
                ? hasBacktestingResult
                  ? '백테스팅'
                  : '작성중'
                : '팔로잉'
            }
            size="small"
          />
        </div>
      </div>
      <div className={styles.rorRow}>
        <span>{decimalFormatter(backtesting.ror)}%</span>
      </div>
      <div className={styles.mddRow}>
        <span>{decimalFormatter(backtesting.mdd)}%</span>
      </div>
      <div className={styles.backtestingRangeRow}>
        <span>
          {backtestingStartDate} ~ {backtestingEndDate}
        </span>
      </div>
    </div>
  );
};
