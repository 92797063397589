export type ArithmeticOperatorKind = '+' | '-' | '*' | '/';

export type TokenKind =
  | 'number'
  | 'arithmeticOperator'
  | 'indicator'
  | 'relationalOperator';

export const arithmeticOperatorKindArray: ArithmeticOperatorKind[] = [
  '+',
  '-',
  '*',
  '/',
];

export type RelationalOperatorKind = '=' | '!=' | '>' | '>=' | '<' | '<=';
export const relationalOperatorKindArray: RelationalOperatorKind[] = [
  '=',
  '!=',
  '>',
  '>=',
  '<',
  '<=',
];

export type IndicatorCategory =
  | 'general'
  | 'trends'
  | 'momentum'
  | 'volatility'
  | 'onchain_data';
export type MarketTiming = 'BTC' | 'ETH';

export type IndicatorInputLabel =
  | '이동평균 기간'
  | '표준편차 계수'
  | '빠른 이동평균 기간'
  | '느린 이동평균 기간'
  | '전환선 기간'
  | '기준선 기간'
  | '선행스팬 2'
  | '%K 기간'
  | '%K 이동평균 기간'
  | '%D 이동평균 기간'
  | '시그널 기간';
