import Switch from 'react-switch';

interface SwitchButtonProps {
  switchOnColor: string;
  switchOnHandleColor: string;
  isSwitchOn: boolean;
  handleToggle?: () => void;
}

const Toggle = ({
  switchOnColor,
  switchOnHandleColor,
  isSwitchOn,
  handleToggle,
}: SwitchButtonProps) => {
  return (
    <Switch
      checked={isSwitchOn}
      onChange={handleToggle || (() => {})}
      onColor={switchOnColor}
      onHandleColor={switchOnHandleColor}
      handleDiameter={16}
      uncheckedIcon={false}
      checkedIcon={false}
      height={12}
      width={28}
    />
  );
};

export default Toggle;
