import {
  validateExprsToFormulas,
  convertExprsToStrategy,
} from 'features/adaptor/validators/';
import { BacktestingAndStrategy } from 'features/schemas/server';
import { getInitialState, StrategyState } from 'features/slices';

export const convertBacktestingSettingsDetailToStrategyState = (
  settingsDetail: BacktestingAndStrategy,
): StrategyState => {
  const state = getInitialState();
  const strategy = settingsDetail.strategy;

  state.name = strategy.name;
  const buyStrategyArray = convertExprsToStrategy(strategy.buy_exprs);
  const sellStrategyArray = convertExprsToStrategy(strategy.sell_exprs || []);
  state.conditionExpression = {
    selected: '',
    buyStrategy: buyStrategyArray,
    sellStrategy: sellStrategyArray,
  };
  state.sellConfig = {
    profit_cut: strategy.profit_cut,
    loss_cut: strategy.loss_cut,
    market_max_holding_minutes: strategy.market_max_holding_minutes,
  };

  state.buyFormulas = validateExprsToFormulas(strategy.buy_exprs);
  state.sellFormulas = validateExprsToFormulas(strategy.sell_exprs || []);
  state.selectedBuyFormulaIndex = 0;
  state.selectedSellFormulaIndex = 0;

  return state;
};
