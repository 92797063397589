import SortAscendIcon from 'images/icons/svgs/sort-ascend.svg';

import SortDefaultIcon from 'images/icons/svgs/sort-default.svg';

import SortDescendIcon from 'images/icons/svgs/sort-descend.svg';

export type SortOrder = 'Default' | 'Ascending' | 'Descending';
interface SortIconProps {
  sortOrder: SortOrder;
}

const SortIcon = ({ sortOrder }: SortIconProps) => {
  if (sortOrder === 'Ascending') {
    return <SortAscendIcon />;
  }

  if (sortOrder === 'Descending') {
    return <SortDescendIcon />;
  }

  return <SortDefaultIcon />;
};

export default SortIcon;
