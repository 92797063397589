import { chartAPI } from 'features/api/chart/base';
import { CandleKind, CryptoExchangeKind } from 'features/api/chart/constants';
import {
  Candle,
  IndicatorInfo,
  TradingViewCandle,
} from 'features/api/chart/schemas';

interface ReadCandlesQuery {
  date?: string;
  market?: string;
  exchange?: CryptoExchangeKind;
}

const readAllIndicatorInfo = async (): Promise<IndicatorInfo[]> => {
  const response = await chartAPI.get<IndicatorInfo[]>('/data/indicators');
  return response.data;
};

const readCandles = async (
  kind: CandleKind,
  params?: ReadCandlesQuery,
): Promise<TradingViewCandle[]> => {
  const response = await chartAPI.get(`/data/${kind}`, {
    params: params,
  });

  return response.data.map((candle: Candle) => {
    return {
      time: Date.parse(candle.created_at),
      open: candle.open,
      high: candle.high,
      low: candle.low,
      close: candle.close,
      volume: candle.volume,
    };
  });
};

export default {
  readAllIndicatorInfo,
  readCandles,
};
