import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CandleKind, logicalOperators } from 'constants/';
import { format } from 'date-fns';

import { convertBacktestingSettingsDetailToStrategyState } from 'features/adaptor/converters/';
import { StrategyArray, Formula, TokenValue } from 'features/schemas/client';
import { BacktestingAndStrategy, SellConfig } from 'features/schemas/server';
import { fireAlert, getNextAlphabet } from 'features/utils';

interface ConditionExpression {
  selected: string;
  buyStrategy: StrategyArray;
  sellStrategy: StrategyArray;
}

const initialConditionalStatement: ConditionExpression = {
  selected: '',
  buyStrategy: [],
  sellStrategy: [],
};

export const initialSellConfig: SellConfig = {
  profit_cut: '5.00',
  loss_cut: '5.00',
  market_max_holding_minutes: '14400',
};

export type StrategyMode = 'buy' | 'sell';

export interface StrategyState {
  name: string;
  mode: StrategyMode;
  conditionExpression: ConditionExpression;
  sellConfig: SellConfig;
  buyFormulas: Formula[];
  sellFormulas: Formula[];
  selectedBuyFormulaIndex: number;
  selectedSellFormulaIndex: number;
}

export const getInitialState: () => StrategyState = () => {
  return {
    name: format(new Date(), 'yyyyMMdd_HHmmss'),
    mode: 'buy',
    conditionExpression: initialConditionalStatement,
    sellConfig: initialSellConfig,
    editInfo: null,
    buyFormulas: [],
    sellFormulas: [],
    selectedBuyFormulaIndex: -1,
    selectedSellFormulaIndex: -1,
  };
};

const _setStrategyName: CaseReducer<StrategyState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.name = action.payload;
};

const _setMode: CaseReducer<StrategyState, PayloadAction<StrategyMode>> = (
  state,
  action,
) => {
  state.mode = action.payload;
};

interface FormulaIndexPayload {
  mode: StrategyMode;
  index: number;
}

const _addNewDefaultBuyFormula: CaseReducer<StrategyState> = (state) => {
  const lastFormula = state.buyFormulas.at(-1);

  const newFormula: Formula = {
    name: getNextAlphabet(lastFormula?.name),
    tokens: [],
    candleKind: 'day',
  };

  // 포뮬라가 하나도 없을 때
  if (!lastFormula) {
    state.buyFormulas = [newFormula];
    state.selectedBuyFormulaIndex = 0;
    state.conditionExpression = {
      ...state.conditionExpression,
      buyStrategy: [newFormula.name],
    };
    return;
  }

  // MEMO: 7개로 제한한 이유, TB-4304
  if (lastFormula && state.buyFormulas.length >= 7) {
    fireAlert({
      text: '포뮬라 생성은 7개까지 가능해요. 기존 포뮬라를 삭제하고 생성해주세요.',
    });
    return;
  }

  state.buyFormulas = [...state.buyFormulas, newFormula];
  state.selectedBuyFormulaIndex =
    state.buyFormulas.length > 0 ? state.buyFormulas.length - 1 : 0;
  state.conditionExpression = {
    ...state.conditionExpression,
    buyStrategy: [
      ...state.conditionExpression.buyStrategy,
      'and',
      newFormula.name,
    ],
  };
};

const _addNewDefaultSellFormula: CaseReducer<StrategyState> = (state) => {
  const lastFormula = state.sellFormulas.at(-1);

  const newFormula: Formula = {
    name: getNextAlphabet(lastFormula?.name),
    tokens: [],
    candleKind: 'day',
  };

  // 포뮬라가 하나도 없을 때
  if (!lastFormula) {
    state.sellFormulas = [newFormula];
    state.selectedSellFormulaIndex = 0;
    state.conditionExpression = {
      ...state.conditionExpression,
      sellStrategy: [newFormula.name],
    };
    return;
  }

  // MEMO: 7개로 제한한 이유, TB-4304
  if (lastFormula && state.sellFormulas.length >= 7) {
    fireAlert({
      text: '포뮬라 생성은 7개까지 가능해요. 기존 포뮬라를 삭제하고 생성해주세요.',
    });
    return;
  }

  state.sellFormulas = [...state.sellFormulas, newFormula];
  state.selectedSellFormulaIndex =
    state.sellFormulas.length > 0 ? state.sellFormulas.length - 1 : 0;
  state.conditionExpression = {
    ...state.conditionExpression,
    sellStrategy: [
      ...state.conditionExpression.sellStrategy,
      'and',
      newFormula.name,
    ],
  };
};

interface FormulaPayload {
  mode: StrategyMode;
  tokens: TokenValue[];
  candleKind: CandleKind;
}

const _setFormula: CaseReducer<StrategyState, PayloadAction<FormulaPayload>> = (
  state,
  action,
) => {
  const { mode, tokens, candleKind } = action.payload;
  const formulas = mode === 'buy' ? 'buyFormulas' : 'sellFormulas';
  const selectedFormulaIndex =
    mode === 'buy'
      ? state.selectedBuyFormulaIndex
      : state.selectedSellFormulaIndex;

  if (selectedFormulaIndex === -1) {
    return;
  }

  const originalFormula = state[formulas][selectedFormulaIndex];
  state[formulas][selectedFormulaIndex] = {
    ...originalFormula,
    tokens,
    candleKind,
  };
};

interface FormulaIndexPayload {
  mode: StrategyMode;
  index: number;
}

const _setSelectedFormulaIndex: CaseReducer<
  StrategyState,
  PayloadAction<FormulaIndexPayload>
> = (state, action) => {
  const { mode, index } = action.payload;
  const selectedFormulaIndex =
    mode === 'buy' ? 'selectedBuyFormulaIndex' : 'selectedSellFormulaIndex';
  state[selectedFormulaIndex] = index;
};

const _setSellConfig: CaseReducer<StrategyState, PayloadAction<SellConfig>> = (
  state,
  action,
) => {
  state.sellConfig = action.payload;
};

const _deleteProfitCut: CaseReducer<StrategyState> = (state) => {
  state.sellConfig.profit_cut = null;
};

const _deleteLossCut: CaseReducer<StrategyState> = (state) => {
  state.sellConfig.loss_cut = null;
};

const _deleteFormulaToken: CaseReducer<
  StrategyState,
  PayloadAction<{ index: number; mode: StrategyMode }>
> = (state, action) => {
  const { index, mode } = action.payload;
  const formulas = mode === 'buy' ? 'buyFormulas' : 'sellFormulas';
  const strategy = mode === 'buy' ? 'buyStrategy' : 'sellStrategy';
  const [deletedFormula] = state[formulas].splice(index, 1);

  if (mode === 'buy') {
    state.selectedBuyFormulaIndex = -1;
  }

  if (mode === 'sell') {
    state.selectedSellFormulaIndex = -1;
  }

  state.conditionExpression = {
    ...state.conditionExpression,
    [strategy]: state.conditionExpression[strategy].filter(
      (x) => x !== deletedFormula.name,
    ),
  };

  // // TB-4626(요구사항3), 포뮬라를 지우면 식의 무결성을 유지하기 위해 관련 연산자도 지워줘야 함
  let newStrategy = structuredClone(
    state.conditionExpression[strategy].filter(
      (x) => x !== deletedFormula.name,
    ),
  );

  // 1. and B and C, Remove leading operators
  while (newStrategy.length > 0 && logicalOperators.includes(newStrategy[0])) {
    newStrategy.shift();
  }

  // 2. A and B and, Remove trailing operators
  while (
    newStrategy.length > 0 &&
    logicalOperators.includes(newStrategy[newStrategy.length - 1])
  ) {
    newStrategy.pop();
  }

  // 3. A and and C, Remove consecutive operators
  newStrategy = newStrategy.filter(
    (item, i) =>
      !(
        logicalOperators.includes(item) &&
        logicalOperators.includes(newStrategy[i + 1])
      ),
  );

  state.conditionExpression = {
    ...state.conditionExpression,
    [strategy]: newStrategy,
  };
};

const _setConditionalStatement: CaseReducer<
  StrategyState,
  PayloadAction<ConditionExpression>
> = (state, action) => {
  const data = action.payload;

  state.conditionExpression = Object.assign({}, state.conditionExpression, {
    ...data,
  });
};

const _importStrategy: CaseReducer<
  StrategyState,
  PayloadAction<BacktestingAndStrategy>
> = (state, action) => {
  const settingsDetail = action.payload;
  return convertBacktestingSettingsDetailToStrategyState(settingsDetail);
};

const _setStrategyInitialState: CaseReducer<
  StrategyState,
  PayloadAction<StrategyState>
> = (state, action) => {
  const data = action.payload;
  return { ...data };
};

export const strategySlice = createSlice({
  name: 'basic',
  initialState: getInitialState(),
  reducers: {
    setStrategyName: _setStrategyName,
    resetStrategy: getInitialState,
    setSellConfig: _setSellConfig,
    deleteProfitCut: _deleteProfitCut,
    deleteLossCut: _deleteLossCut,
    deleteFormulaToken: _deleteFormulaToken,
    setConditionalStatement: _setConditionalStatement,
    importStrategy: _importStrategy,
    setStrategyInitialState: _setStrategyInitialState,
    setMode: _setMode,
    addNewDefaultBuyFormula: _addNewDefaultBuyFormula,
    addNewDefaultSellFormula: _addNewDefaultSellFormula,
    setFormula: _setFormula,
    setSelectedFormulaIndex: _setSelectedFormulaIndex,
  },
});

export const {
  setStrategyName,
  resetStrategy,
  setSellConfig,
  deleteProfitCut,
  deleteLossCut,
  deleteFormulaToken,
  setConditionalStatement,
  importStrategy,
  setStrategyInitialState,
  setMode,
  addNewDefaultBuyFormula,
  addNewDefaultSellFormula,
  setFormula,
  setSelectedFormulaIndex,
} = strategySlice.actions;
