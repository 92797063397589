import * as Sentry from '@sentry/nextjs';

import { useAppSelector } from 'store';

import backtestingMutation from 'hooks/useMutation/backtesting';

import { convertStateToBacktestingForm } from 'features/adaptor/converters/';
import { amplitudeEventNames, trackEvent } from 'features/amplitude';
import { formatCurrency, parseNumberFormatString } from 'features/utils';

import Button from 'components/Button';

import { ConfirmStartBacktestingModalRow } from 'components/Modal/ConfirmStartBacktestingModal/ConfirmStartBacktestingModalRow';
import { SimpleModalProps } from 'components/Modal/ModalTypes';
import SelectModal from 'components/Modal/SelectModal/SelectModal';

import TextHighlight from 'components/Textbox/TextHighlight';

import styles from 'components/Modal/ConfirmStartBacktestingModal/ConfirmStartBacktestingModal.module.scss';

const ConfirmStartBacktestingModal = ({ onClose }: SimpleModalProps) => {
  const [settingsState, strategyState] = useAppSelector((state) => {
    return [state.settings, state.strategy];
  });

  const form = convertStateToBacktestingForm(settingsState, strategyState);
  const { mutate } = backtestingMutation.useCreateBacktestingTaskMutation();

  const handleCancelClick = () => {
    trackEvent(amplitudeEventNames.BTN_BT_CANCEL);
    onClose();
  };

  const handleStartClick = async () => {
    trackEvent(amplitudeEventNames.BTN_BT_START, form);

    Sentry.captureMessage('backtesting form submit!!', {
      level: 'info',
      extra: { form },
    });
    mutate(form);
  };

  return (
    <SelectModal
      title={'백테스팅을 시작하시겠습니까?'}
      onClose={onClose}
      width={600}
      height={'auto'}
    >
      <div className={styles.root}>
        <ConfirmStartBacktestingModalRow title="전략 이름">
          <span className={styles.title}>{settingsState.name}</span>
        </ConfirmStartBacktestingModalRow>
        <ConfirmStartBacktestingModalRow title="투자원금">
          <span className={styles.priceText}>
            {formatCurrency(
              parseNumberFormatString(settingsState.totalAmount) * 10000,
            )}
            원
          </span>
        </ConfirmStartBacktestingModalRow>
        <ConfirmStartBacktestingModalRow
          title="백테스팅 기간"
          extraClassName={styles.backtestingRangeContainer}
        >
          <div className={styles.backtestingRangeWrapper}>
            <span className={styles.backtestingRange}>
              {`${settingsState.day[0]} ${settingsState.time[0]} ~ ${settingsState.day[1]} ${settingsState.time[1]}`}
            </span>
            <TextHighlight
              text={`총 ${settingsState.dayGaps[0]}일 ${settingsState.dayGaps[1]}시간 ${settingsState.dayGaps[2]}분`}
            />
          </div>
        </ConfirmStartBacktestingModalRow>

        <div className={styles.buttonWrapper}>
          <Button onClick={handleCancelClick} theme="neutral3" size="medium">
            취소하기
          </Button>
          <Button onClick={handleStartClick} theme="primary1" size="medium">
            시작하기
          </Button>
        </div>
      </div>
    </SelectModal>
  );
};

export default ConfirmStartBacktestingModal;
