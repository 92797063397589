import InfoIcon from 'images/icons/svgs/ic-info.svg';
import SmallInfoIcon from 'images/icons/svgs/ic-info_s.svg';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import styles from './TooltipIcon.module.scss';
interface TooltipIconProps {
  id: string;
  isSmall?: boolean;
}

const TooltipIcon = ({ id, isSmall }: TooltipIconProps) => {
  const handleClick = () => {
    trackEvent(amplitudeEventNames.BTN_TOOLTIP);
  };

  if (isSmall) {
    return (
      <SmallInfoIcon
        data-tooltip-id={id}
        className={styles.root}
        onClick={handleClick}
      />
    );
  }

  return (
    <InfoIcon
      data-tooltip-id={id}
      className={styles.root}
      onClick={handleClick}
    />
  );
};

export default TooltipIcon;
