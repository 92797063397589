import { useRouter } from 'next/router';

import { useEffect } from 'react';

import { useAppSelector } from 'store';

import api from 'features/api/chart/backtesting';

export const useBacktestingRevocation = () => {
  const router = useRouter();
  const backtestState = useAppSelector((state) => state.backtest);

  useEffect(() => {
    const handleRevokeBeforeUnload = () => {
      if (backtestState.requestId) {
        api.revokeTask(backtestState.requestId!);
      }
    };

    const handleNavigationChange = () => {
      handleRevokeBeforeUnload();
    };

    window.addEventListener('beforeunload', handleRevokeBeforeUnload); // navigate or close the window
    window.addEventListener('popstate', handleNavigationChange); // go back or forward
    router.events.on('routeChangeStart', handleNavigationChange);

    return () => {
      window.removeEventListener('beforeunload', handleRevokeBeforeUnload);
      window.removeEventListener('popstate', handleNavigationChange);
      router.events.off('routeChangeStart', handleNavigationChange);
    };
  }, [router.events, backtestState.requestId]);

  return null;
};
