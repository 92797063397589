interface NumberInputFieldProps {
  label: string;
  numberMin?: number;
  numberMax?: number;
  value?: number;
  onChange: (value: number) => void;
}

import styles from './NumberInputField.module.scss';

const NumberInputField = ({
  label,
  numberMin = 1,
  numberMax = 1000,
  value,
  onChange,
  ...props
}: NumberInputFieldProps) => {
  return (
    <div className={styles.wrapper}>
      <label htmlFor={label} className={styles.label}>
        {label}
      </label>
      <input
        type="number"
        className={styles.input}
        id={label}
        min={numberMin}
        max={numberMax}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        {...props}
      />
    </div>
  );
};

export default NumberInputField;
