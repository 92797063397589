import {
  TradingViewCandleToCandleKind,
  TradingViewCandleKind,
  COINONE,
} from 'constants/';

import {
  CachedCandleData,
  lastRequestedKey,
} from 'widgets/backtesting/TAChart/candledata';

import { addBuyStrategyToPreviewExprs } from 'features/adaptor/converters';
import { PreviewKind } from 'features/api/chart/constants';
import { BacktestingPreviewForm } from 'features/schemas/server';
import {
  ChartData,
  BacktestingSettingsState,
  StrategyState,
} from 'features/slices';
import { convertDaysToMinutes } from 'features/utils';

const convertChartSymbolToPreviewMarket = (symbol: string): string => {
  const market = symbol.split(':')[1];
  const [code, currency] = market.split('/');
  return `${currency}-${code}`;
};
interface PreviewFormProps {
  kind: PreviewKind;
  settingState: BacktestingSettingsState;
  chartState: ChartData;
  strategyState: StrategyState;
}

export const convertStateToPreviewForm = ({
  kind,
  settingState,
  chartState,
  strategyState,
}: PreviewFormProps): BacktestingPreviewForm => {
  const { buyFormulas, sellFormulas, conditionExpression } = strategyState;
  const market = convertChartSymbolToPreviewMarket(chartState.symbol);
  const currentTimestamp = Date.now();
  let endTimestamp = chartState.visibleRange
    ? chartState.visibleRange!.to * 1000
    : 0;
  if (currentTimestamp < endTimestamp) {
    endTimestamp = currentTimestamp;
  }
  const startDate = chartState.visibleRange
    ? new Date(chartState.visibleRange!.from * 1000)
        .toISOString()
        .replace('Z', '')
    : new Date().toISOString().replace('Z', '');
  const endDate = new Date(endTimestamp).toISOString().replace('Z', '');
  const chartCandleKind =
    TradingViewCandleToCandleKind[chartState.unit as TradingViewCandleKind];
  let dataStartAt = startDate;
  if (lastRequestedKey.key !== null) {
    const currentCandleFetchRecordString = localStorage.getItem(
      lastRequestedKey.key,
    );
    const currentCandleFetchRecord: CachedCandleData =
      currentCandleFetchRecordString
        ? (JSON.parse(currentCandleFetchRecordString) as CachedCandleData)
        : {
            oldestTimestamp: 0,
            latestTimestamp: 0,
            candles: {},
          };

    dataStartAt = new Date(
      currentCandleFetchRecord.oldestTimestamp,
    ).toISOString();
  }

  return {
    name: settingState.name,
    data_start_at: dataStartAt,
    strategy: {
      name: strategyState.name,

      buy_exprs: addBuyStrategyToPreviewExprs(
        buyFormulas,
        conditionExpression.buyStrategy,
        chartCandleKind,
      ),
      sell_exprs: addBuyStrategyToPreviewExprs(
        sellFormulas,
        conditionExpression.sellStrategy,
        chartCandleKind,
      ),
      profit_cut: strategyState.sellConfig.profit_cut,
      loss_cut: strategyState.sellConfig.loss_cut,
      market_max_holding_minutes:
        strategyState.sellConfig.market_max_holding_minutes,
    },
    config: {
      start: startDate,
      end: endDate,
      crypto_exchange: COINONE,
      market: market,
      market_max_holding_minutes:
        kind === 'buy'
          ? undefined
          : convertDaysToMinutes(
              strategyState.sellConfig.market_max_holding_minutes,
            ),
    },
    preview: kind,
  };
};
