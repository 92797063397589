import React from 'react';

import clsx from 'clsx';

import styles from './index.module.scss';

export type ButtonTheme =
  | 'none'
  | 'primary1'
  | 'primary2'
  | 'primary3'
  | 'secondary1'
  | 'secondary2'
  | 'neutral1'
  | 'neutral2'
  | 'neutral3';

type ButtonSize =
  | 'small'
  | 'medium'
  | 'large'
  | 'smallSquare'
  | 'mediumSquare'
  | 'none';

interface ButtonProps {
  type?: 'button' | 'submit';
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isClickable?: boolean;
  size: ButtonSize;
  theme: ButtonTheme;
  isFullWidth?: boolean;
  testId?: string;
  extraClassNames?: string[];
  children?: React.ReactNode;
}

export const Button = ({
  type = 'button',
  onClick = () => {},
  isClickable = true,
  size,
  theme,
  isFullWidth = false,
  testId,
  extraClassNames = [],
  children,
  ...props
}: ButtonProps) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!isClickable) {
      return;
    }

    e.preventDefault();
    onClick(e);
  };

  return (
    <button
      type={type}
      onClick={type === 'button' ? handleClick : undefined}
      className={clsx(
        styles.root,
        !isClickable && styles.disabled,
        styles[size],
        theme && styles[theme],
        isFullWidth && styles.fullWidth,
        extraClassNames.join(' '),
      )}
      data-cy={testId}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
