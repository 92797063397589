import SymbolLogo from 'images/icons/svgs/logo_symbol.svg';
import TextLogo from 'images/icons/svgs/logo_text.svg';

import styles from 'components/Loading/Splash.module.scss';

const Splash = () => {
  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <SymbolLogo />
        <TextLogo />
      </div>
      <div className={styles.text}>
        <p>디지털 자산 자동 투자의 시작</p>
        <p>트레이딩뱅크</p>
      </div>
    </div>
  );
};

export default Splash;
