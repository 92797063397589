import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from 'store';

import dataQuery from 'hooks/useQuery/data';
import marketQuery from 'hooks/useQuery/market';

import strategyQueries from 'hooks/useQuery/strategy';

import { BacktestingAndStrategy } from 'features/schemas/server';
import { importSettings, importStrategy } from 'features/slices';

type FetchStrategyInfo = {
  strategyId: string;
  callback: (backtestingAndStrategy: BacktestingAndStrategy) => void;
};

export const useLoadStrategy = () => {
  const { data: markets = [] } = marketQuery.useAllMarkets();
  const { data: indicators = [] } = dataQuery.useAllDataIndicatorInfo();
  const [fetchStrategyInfo, setFetchStrategyInfo] =
    useState<FetchStrategyInfo | null>(null);
  const { data: backtestingAndStrategy } =
    strategyQueries.useBacktestingAndStrategy({
      backtestingId: fetchStrategyInfo?.strategyId,
    });
  const dispatch = useAppDispatch();

  const loadStrategy = useCallback(
    (backtestingAndStrategy: BacktestingAndStrategy) => {
      dispatch(importSettings(backtestingAndStrategy));
      dispatch(importStrategy(backtestingAndStrategy));
    },
    [dispatch],
  );

  useEffect(() => {
    if (backtestingAndStrategy && markets && indicators && fetchStrategyInfo) {
      fetchStrategyInfo?.callback(backtestingAndStrategy);
    }
  }, [
    fetchStrategyInfo,
    backtestingAndStrategy,
    markets,
    indicators,
    loadStrategy,
  ]);

  const fetchStrategy = (
    strategyId: string,
    cb: (backtestingAndStrategy: BacktestingAndStrategy) => void,
  ) => {
    setFetchStrategyInfo({ strategyId, callback: cb });
  };

  return { loadStrategy, fetchStrategy };
};
