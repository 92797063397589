import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'constants/';

import { marketKrNameMap } from 'features/api/chart/constants';

import api from 'features/api/chart/market';
import { Market } from 'features/api/chart/schemas';

const useAllMarkets = () =>
  useQuery<Market[]>([queryKeys.MARKETS], () => api.readAllMarkets(), {
    onSuccess: (markets) => {
      return markets.forEach((market) => {
        const name = market.name.replace(/^KRW-/i, '');
        market.kr_name = marketKrNameMap.get(name) || '';
      });
    },
  });

export default {
  useAllMarkets,
};
