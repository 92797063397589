import {
  relationalOperatorKindArray,
  arithmeticOperatorKindArray,
  RelationalOperatorKind,
  ArithmeticOperatorKind,
} from 'constants/';

import {
  LocalToken,
  TokenValue,
  MarketIndicatorInfo,
} from 'features/schemas/client';

export const convertLocalTokensToFormula = (
  tokens: LocalToken[],
): TokenValue[] =>
  structuredClone(tokens).map((token) => {
    const { type, value } = token;
    if (type === 'number') {
      return value.toString();
    }

    return value;
  });

export const convertReduxTokensToString = (tokens: TokenValue[]): string => {
  return tokens
    .map((token) => {
      if (typeof token === 'object') {
        const { market, shift, indicator, args } = token as MarketIndicatorInfo;
        const argsStr = args.length ? `(${args.join(', ')})` : '';
        const marketText = market ? market.replaceAll('KRW-', '') : '';
        return `${marketText} ${shift}봉전 ${indicator.kr_name} ${argsStr}`;
      }
      // 쉼표 처리, e.g., 6000 -> 6,000
      if (isNaN(Number(token))) {
        return token;
      }

      return Number(token).toLocaleString();
    })
    .join(' ');
};

export const convertReduxTokensToLocal = (
  tokens: TokenValue[],
): LocalToken[] => {
  return tokens.map((token) => {
    let result: LocalToken | undefined = undefined;

    switch (typeof token) {
      case 'string':
        if (
          relationalOperatorKindArray.includes(token as RelationalOperatorKind)
        ) {
          result = {
            type: 'relationalOperator',
            value: token as RelationalOperatorKind,
          };
        } else if (
          arithmeticOperatorKindArray.includes(token as ArithmeticOperatorKind)
        ) {
          result = {
            type: 'arithmeticOperator',
            value: token as ArithmeticOperatorKind,
          };
        } else {
          result = {
            type: 'number',
            value: Number(token),
          };
        }
        break;
      case 'number':
        result = {
          type: 'number',
          value: token as number,
        };
        break;
      case 'object':
        result = {
          type: 'indicator',
          value: token as MarketIndicatorInfo,
        };
        break;
      default:
        throw new Error(`Unsupported token type: ${typeof token}`);
    }
    if (result !== undefined) {
      return result;
    }

    throw new Error(`Unexpected error: 'result' is undefined.`);
  });
};

const isAlternatingList = (tokens: LocalToken[]): boolean => {
  for (let i = 0; i < tokens.length - 1; i++) {
    const currentType = tokens[i].type;
    const nextType = tokens[i + 1].type;

    if (currentType === nextType) {
      return false;
    } else if (currentType === 'indicator' && nextType === 'number') {
      return false;
    } else if (currentType === 'number' && nextType === 'indicator') {
      return false;
    }
  }
  return true;
};

const isEvenLength = (arr: LocalToken[]) => arr.length % 2 === 0;

export const validateTokens = (tokens: LocalToken[]): boolean => {
  const relationalOperatorIndex = tokens.findIndex(
    (token) => token.type === 'relationalOperator',
  );

  if (relationalOperatorIndex === -1) {
    return false;
  }
  const leftTokens = tokens.slice(0, relationalOperatorIndex);
  const rightTokens = tokens.slice(relationalOperatorIndex + 1);

  // Check if left and right sides have at least one token and odd
  if (isEvenLength(leftTokens) || isEvenLength(rightTokens)) {
    return false;
  }

  // Check if left side starts with indicator
  if (leftTokens[0].type === undefined || leftTokens[0].type !== 'indicator') {
    return false;
  } else if (['arithmeticOperator'].includes(rightTokens[0].type)) {
    return false;
  }

  // Check if the list contains alternating string and number
  if (!isAlternatingList(leftTokens) || !isAlternatingList(rightTokens)) {
    return false;
  }

  return true;
};

export const convertLocalTokensToString = (tokens: LocalToken[]): string => {
  return tokens
    .map((token) => {
      const { type, value } = token;

      if (type === 'indicator' && value.args !== undefined) {
        const { market, shift, indicator, args } = value;
        const argsStr = args.length > 0 ? `(${args.join(', ')})` : '';
        const marketText = market !== null ? market.replaceAll('KRW-', '') : '';
        return `${marketText} ${shift}봉전 ${indicator.kr_name} ${argsStr}`;
      }

      if (type === 'number') {
        return value.toLocaleString();
      }

      return value.toString();
    })
    .join(' ');
};
