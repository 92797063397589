import { differenceInDays, format } from 'date-fns';

import { BacktestingWithConfig } from 'features/schemas/server';
import {
  BacktestingSettingsState,
  initialBacktestingSetting,
} from 'features/slices';
import {
  countTimeGap,
  formatCurrency,
  splitDateTimeOfISOString,
} from 'features/utils';
const _differenceInTime = (start: string, end: string) => {
  const startTime = start.split(':').map((time) => parseInt(time));
  const endTime = end.split(':').map((time) => parseInt(time));

  const hourGap = startTime[0] - endTime[0];
  const minuteGap = startTime[1] - endTime[1];

  return countTimeGap(hourGap, minuteGap);
};

export const saveBacktestingToReduxState = (
  settings: BacktestingWithConfig,
): BacktestingSettingsState => {
  const state = initialBacktestingSetting();
  const config = settings.config;

  state.id = settings.id;
  state.name = settings.name;
  state.exchange = 'coinone';
  state.totalAmount = config.principal
    ? formatCurrency(config.principal / 10_000) ?? '0'
    : '0';
  state.slippage = formatCurrency(config.slippage) ?? '0';
  const { date: startDate, time: startTime } = splitDateTimeOfISOString(
    config.start,
  );
  const { date: endDate, time: endTime } = splitDateTimeOfISOString(config.end);
  state.day = [startDate, endDate];
  state.time = [startTime, endTime];

  const difference = differenceInDays(new Date(startDate), new Date(endDate));
  const [calculatedHour, calculatedMinute] = _differenceInTime(
    startTime,
    endTime,
  );

  state.dayGaps = [Math.abs(difference), calculatedHour, calculatedMinute];
  state.markets =
    config.markets?.map((market) => ({
      name: market,
      has_warning: false,
      is_active: true,
      dollar_volume: 0, // 클라이언트에서 저장한 거래대금은 사실 아무런 의미가 없고 그저 타입을 맞춰주기 위한 할당
    })) || [];
  state.createdAt = format(
    new Date(settings.created_at),
    'yyyy-MM-dd HH:mm:ss',
  );
  return state;
};
