import { addStrategyToFormulaExprs } from 'features/adaptor/converters/';
import { BacktestingConfig, BacktestingForm } from 'features/schemas/server/';
import { BacktestingSettingsState, StrategyState } from 'features/slices';
import { parseNumberFormatString } from 'features/utils';

const convertSettingsToBacktestingConfig = (
  settings: BacktestingSettingsState,
  strategy: StrategyState,
): BacktestingConfig => {
  const start = `${settings.day[0]}T${settings.time[0]}:00`;
  const end = `${settings.day[1]}T${settings.time[1]}:00`;
  const { sellConfig } = strategy;

  return {
    start,
    end,
    crypto_exchange: settings.exchange,
    markets: settings.markets.map(({ name }) => name),
    excluded_markets: [],
    principal: parseNumberFormatString(settings.totalAmount) * 10_000,
    slippage: 0.1,
    market_max_holding_minutes: Number(sellConfig.market_max_holding_minutes),
  };
};

export const convertStateToBacktestingForm = (
  settings: BacktestingSettingsState,
  strategy: StrategyState,
): BacktestingForm => {
  const { buyFormulas, sellFormulas, conditionExpression, sellConfig, name } =
    strategy;
  const { buyStrategy, sellStrategy } = conditionExpression;

  return {
    name,
    strategy: {
      name,
      buy_exprs: addStrategyToFormulaExprs(buyFormulas, buyStrategy),
      sell_exprs: addStrategyToFormulaExprs(sellFormulas, sellStrategy),
      profit_cut: sellConfig.profit_cut,
      loss_cut: sellConfig.loss_cut,
      market_max_holding_minutes: sellConfig.market_max_holding_minutes,
    },
    config: convertSettingsToBacktestingConfig(settings, strategy),
  };
};
