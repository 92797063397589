import { Category } from 'features/api/chart/schemas/form';
import { PublicBacktesting } from 'features/schemas/server';
import { splitDateTimeOfISOString } from 'features/utils';

export type ParsedPublicBacktesting = {
  id: string;
  backtestingId: string;
  backtestingDate: string;
  title: string;
  followers: number;
  ror: number;
  mdd: number;
  fee: number;
  author: string;
  category: Category;
};

export const parsePublicBacktestingAndStrategy: (
  item: PublicBacktesting,
) => ParsedPublicBacktesting = (item) => {
  const { date: startsAtDate } = splitDateTimeOfISOString(
    item.backtesting_start,
    true,
  );
  const { date: endsAtDate } = splitDateTimeOfISOString(
    item.backtesting_end,
    true,
  );
  return {
    id: item.id,
    backtestingId: item.id,
    backtestingDate: `${startsAtDate} ~ ${endsAtDate}`,
    title: item.name,
    followers: item.followers ? item.followers : 0,
    ror: item.ror ? item.ror : 0,
    mdd: item.mdd ? item.mdd : 0,
    fee: 0,
    author: item.author.toString(),
    category: item.category,
  };
};
