export const selectMarketModalLowLiquidityFilterTooltip = {
  info: `<필터링 기준>
  최근 24시간 거래대금 10억 이상`,
  meaning: `: 유동성이 낮은 종목들은 백테스트와 실거래 간 차이가 크게 날 수 있어요. 손실을 최소화 하기 위해, 유동성이 낮은 종목은 선택할 수 없습니다.
  `,
};

export const cumulMarginTooltip = {
  title: '수익률 그래프',
  body: [
    {
      info: '[수익률 그래프]',
      meaning: '((자산 - 투자 원금) / 투자 원금) * 100',
    },
    {
      info: '[비트코인 가격 변화율 그래프]',
      meaning:
        '(투자 끝 시점 비트코인 가격 - 투자 시작 시점 비트코인 가격) / 투자 시작 시점 비트코인 가격 * 100',
    },
  ],
};

export const mddTooltip = {
  title: 'MDD 그래프',
  body: [
    {
      info: '[MDD 그래프]',
      meaning: '(최대 손실 발생 지점의 자산 - 최고점 자산) / 최고점 자산 * 100',
    },
    {
      info: '[비트코인 MDD 그래프]',
      meaning:
        '(비트코인 최대 손실 발생 지점의 가격 - 비트코인 최고점 가격) / 비트코인 최고점 가격 * 100',
    },
  ],
};

export const backtestSummaryTopLeftTooltip = {
  title: '',
  body: [
    {
      info: '[누적 수익률]',
      meaning:
        '백테스팅 기간 동안 투자 원금 대비 자산의 변화를 백분율로 나타내요.',
    },
    {
      info: '[CAGR]',
      meaning: `누적 수익률을 연율화한 연평균 수익률을 나타내요. 
        단, 백테스팅 기간이 1년 미만일 경우는 계산되지 않아요.`,
    },
    {
      info: '[MDD]',
      meaning:
        '최대 낙폭으로 백테스팅 기간 동안 자산이 최대로 떨어졌을 때의 손실률을 나타내요. 스트레스 지수라고도 불려요.',
    },
  ],
};

export const backtestSummaryBottomLeftTooltip = {
  title: '',
  body: [
    {
      info: '[회전율]',
      meaning:
        '투자 기간 매수금액과 매도금액의 합을 투자원금과 비교하여 백분율로 나타낸 지표예요. ',
    },
  ],
};
