import { useEffect, useState } from 'react';

import { IndicatorInputLabel, MarketTiming } from 'constants/';

import { IndicatorInfo, Market } from 'features/api/chart/schemas';

import { MarketIndicatorInfo } from 'features/schemas/client';

import Button from 'components/Button';
import IndicatorDropdown from 'components/Dropdown/IndicatorDropdown';
import TooltipIcon from 'components/Icon/TooltipIcon';

import Radio from 'components/Input/Radio';
import Toggle from 'components/Input/Toggle';
import NumberInputField from 'components/InputField/NumberInputField';
import { SelectValueModalProps } from 'components/Modal/ModalTypes';

import SelectModal from 'components/Modal/SelectModal/SelectModal';
import RatelTooltip from 'components/RatelTooltip';

import styles from 'components/Modal/SelectIndicatorModal/SelectIndicatorModal.module.scss';
import colors from 'styles/constants/_colors.module.scss';

interface IndicatorInputInfo {
  label: IndicatorInputLabel;
  defaultValue: number;
}

const determineIndicatorInputs = (
  indicator: IndicatorInfo | null,
): IndicatorInputInfo[] | null => {
  if (!indicator) {
    return null;
  }

  const { name } = indicator;
  const namePrefix = name.split('/')[0];

  switch (namePrefix) {
    case 'candle':
      return null;

    case 'vma':
    case 'cmf':
    case 'cci':
      return [
        {
          label: '이동평균 기간',
          defaultValue: 20,
        },
      ];

    case 'ma':
    case 'fisher':
      return [
        {
          label: '이동평균 기간',
          defaultValue: 10,
        },
      ];

    case 'adi':
    case 'rsi':
    case 'mfi':
    case 'vroc':
      return [
        {
          label: '이동평균 기간',
          defaultValue: 14,
        },
      ];

    case 'pvo':
      return [
        {
          label: '빠른 이동평균 기간',
          defaultValue: 5,
        },
        {
          label: '느린 이동평균 기간',
          defaultValue: 10,
        },
      ];

    case 'bb':
      return [
        { label: '이동평균 기간', defaultValue: 20 },
        { label: '표준편차 계수', defaultValue: 2 },
      ];

    case 'ichimoku':
      return [
        { label: '전환선 기간', defaultValue: 9 },
        { label: '기준선 기간', defaultValue: 26 },
        { label: '선행스팬 2', defaultValue: 52 },
      ];

    case 'stoch':
      return [
        { label: '%K 기간', defaultValue: 14 },
        { label: '%K 이동평균 기간', defaultValue: 3 },
        { label: '%D 이동평균 기간', defaultValue: 3 },
      ];

    case 'macd':
      return [
        { label: '빠른 이동평균 기간', defaultValue: 12 },
        { label: '느린 이동평균 기간', defaultValue: 26 },
        { label: '시그널 기간', defaultValue: 9 },
      ];

    default:
      return null;
  }
};

interface IndicatorAndMarketInfoList {
  markets: Market[];
  indicators: IndicatorInfo[];
}

type SelectIndicatorModalProps = SelectValueModalProps<MarketIndicatorInfo> & {
  title?: string;
  indicatorAndMarketInfo: IndicatorAndMarketInfoList;
  excludedIndicatorKrNames?: string[];
  indicatorContext: MarketIndicatorInfo | undefined;
};

interface IndicatorInputInfo {
  label: IndicatorInputLabel;
  defaultValue: number;
}

function overrideFloatAsInt(value: number): number {
  return Math.floor(value);
}

function adjustValue(label: IndicatorInputLabel, value: number): number {
  if (label === '표준편차 계수') {
    return value <= 0.001 ? 0.001 : value > 50 ? 50 : value;
  } else {
    return value <= 2 ? 2 : value > 1000 ? 1000 : overrideFloatAsInt(value);
  }
}

const SelectIndicatorModal = ({
  onClose,
  onSelect,
  indicatorAndMarketInfo,
  title,
  excludedIndicatorKrNames,
  indicatorContext,
}: SelectIndicatorModalProps) => {
  const [isMarketTimingAble, setIsMarketTimingAble] = useState(true);
  const [marketTiming, setMarketTiming] = useState<MarketTiming>(
    indicatorContext?.market
      ? (indicatorContext.market.replaceAll('KRW-', '') as MarketTiming)
      : 'BTC',
  );
  const [selectedIndicator, setSelectedIndicator] =
    useState<IndicatorInfo | null>(indicatorContext?.indicator ?? null);
  const [useMarketTiming, setUseMarketTiming] = useState<boolean>(
    Boolean(indicatorContext?.market) ?? false,
  );
  const isValid = Boolean(selectedIndicator);

  useEffect(() => {
    if (selectedIndicator?.name === 'mktcap') {
      setIsMarketTimingAble(false);
      return;
    }

    setIsMarketTimingAble(true);
  }, [selectedIndicator?.name]);

  const toggleMarketTiming = () => {
    setUseMarketTiming((prev) => !prev);
  };

  const getInitialIndicatorArgs = (selectedIndicator: IndicatorInfo | null) => {
    const inputInfos = determineIndicatorInputs(selectedIndicator);

    return inputInfos
      ? inputInfos.reduce<{ [key in IndicatorInputLabel]?: number }>(
          (acc, info) => {
            acc[info.label] = info.defaultValue;
            return acc;
          },
          {},
        )
      : null;
  };

  const indicatorInputInfos: IndicatorInputInfo[] | null =
    determineIndicatorInputs(selectedIndicator);

  const convertArgsArrayToObj = (args: number[]) => {
    if (!indicatorInputInfos) {
      return null;
    }
    return indicatorInputInfos.reduce<{
      [key in IndicatorInputLabel]?: number;
    }>((acc, info, index) => {
      acc[info.label] = args[index];
      return acc;
    }, {});
  };

  const [shift, setShift] = useState<number>(indicatorContext?.shift ?? 1);
  const initialArgsObj = indicatorContext?.args
    ? convertArgsArrayToObj(indicatorContext?.args)
    : getInitialIndicatorArgs(selectedIndicator);
  const [indicatorArgs, setIndicatorArgs] = useState<
    { [key in IndicatorInputLabel]?: number } | null
  >(initialArgsObj);

  const handleInputChange = (label: IndicatorInputLabel, value: number) => {
    setIndicatorArgs((prev) => ({
      ...prev,
      [label]: adjustValue(label, value), // 최대, 최소값 적용, stdDev 를 제외하고 모두 정수
    }));
  };

  const handleNthCandleChange = (value: number) => {
    setShift(value <= 1 ? 1 : value > 100 ? 100 : overrideFloatAsInt(value));
  };

  const handleIndicatorSelect = (indicator: IndicatorInfo) => {
    setSelectedIndicator(indicator);
    setShift(1); // 다른 지표를 선택하면 다시 1봉전이 되어야 한다
    // selectedIndicator.name 가 달라지면 value 를 defaultValue 로 변경

    // TB-4623
    setIndicatorArgs(null);

    const defaultParams = determineIndicatorInputs(indicator);
    defaultParams?.forEach((param) => {
      setIndicatorArgs((prev) => ({
        ...prev,
        [param.label]: param.defaultValue,
      }));
    });
  };

  const onSubmit = () => {
    // SelectValueModalProps onSubmit에 context를 초기화 하도록 했다

    if (isValid) {
      const baseArgs = getInitialIndicatorArgs(selectedIndicator);
      const finalArgs = indicatorArgs
        ? { ...baseArgs, ...indicatorArgs }
        : baseArgs;

      onSelect({
        market: useMarketTiming ? marketTiming : null,
        indicator: selectedIndicator!,
        shift,
        args: finalArgs ? Object.values(finalArgs) : [],
      });
    }
  };

  return (
    <SelectModal
      title={title || '포뮬라에 사용할 지표를 선택하세요'}
      onClose={onClose}
      width={480}
      height={'auto'}
    >
      <div className={styles.root}>
        {isMarketTimingAble && (
          <div className={styles.marketTimingContainer}>
            <div className={styles.marketTimingTitleContainer}>
              <span className={styles.marketTimingTitle}>마켓 타이밍 적용</span>
              <TooltipIcon id="marketTiming" />
              <RatelTooltip
                id="marketTiming"
                title="[마켓 타이밍]"
                description={`: ‘마켓 타이밍'을 적용하면 ‘해당 종목’만의 지표를 사용할 수
              있습니다. 시장의 상승이나 하락에 대응하는 전략을 생성하고자 할
              때 적용합니다.`}
                example={`ex. ‘비트코인 상대강도지수<30’ 조건에 선택한 종목을 거래하고 싶은 경우, 마켓 타이밍을 BTC로 적용 후 상대강도지수 지표를 선택할 수 있습니다.`}
              />
              <Toggle
                isSwitchOn={useMarketTiming}
                handleToggle={toggleMarketTiming}
                switchOnColor={colors.p70}
                switchOnHandleColor={colors.white}
              />
            </div>
            <div className={styles.marketTimingToggleContainer}>
              <Radio
                title="BTC"
                isSelected={marketTiming === 'BTC'}
                onChange={() => setMarketTiming('BTC')}
                disabled={!useMarketTiming}
              />
              <Radio
                title="ETH"
                isSelected={marketTiming === 'ETH'}
                onChange={() => setMarketTiming('ETH')}
                disabled={!useMarketTiming}
              />
            </div>
          </div>
        )}

        <div className={styles.selectorWrapper}>
          <div className={styles.selectorTitle}>지표</div>
          <IndicatorDropdown
            value={selectedIndicator}
            setValue={handleIndicatorSelect}
            indicators={indicatorAndMarketInfo.indicators}
            excludedIndicatorKrNames={excludedIndicatorKrNames}
          />
        </div>
        <div className={styles.inputsContainer}>
          {selectedIndicator && (
            <NumberInputField
              label={'봉전'}
              value={shift}
              onChange={handleNthCandleChange}
            />
          )}
          {indicatorInputInfos?.map((inputInfo) => {
            return (
              <NumberInputField
                key={inputInfo.label}
                label={inputInfo.label}
                value={
                  indicatorArgs
                    ? indicatorArgs[inputInfo.label]
                    : inputInfo.defaultValue
                }
                onChange={(value) => handleInputChange(inputInfo.label, value)}
              />
            );
          })}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            onClick={onSubmit}
            isClickable={isValid}
            theme="primary1"
            size="medium"
            isFullWidth
          >
            완료
          </Button>
        </div>
      </div>
    </SelectModal>
  );
};

export default SelectIndicatorModal;
