import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import {
  backtestSlice,
  backtestingSettingsSlice,
  chartDataSlice,
  featureToggleSlice,
  previewSlice,
  strategySlice,
  tradingrobotSlice,
} from 'features/slices';

export const store = configureStore({
  reducer: {
    chartData: chartDataSlice.reducer,
    backtest: backtestSlice.reducer,
    settings: backtestingSettingsSlice.reducer,
    strategy: strategySlice.reducer,
    preview: previewSlice.reducer,
    featureToggle: featureToggleSlice.reducer,
    tradingrobot: tradingrobotSlice.reducer,
  },
});

const rootReducer = combineReducers({
  chartData: chartDataSlice.reducer,
  backtest: backtestSlice.reducer,
  settings: backtestingSettingsSlice.reducer,
  strategy: strategySlice.reducer,
  preview: previewSlice.reducer,
  featureToggle: featureToggleSlice.reducer,
  tradingrobot: tradingrobotSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
