import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'store';

export const useInputDispatch = (
  fn: ActionCreatorWithPayload<[string, string], string>,
) => {
  const dispatch = useAppDispatch();

  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetId = e.currentTarget.id;
    const targetValue = e.currentTarget.value;
    dispatch(fn([targetId, targetValue]));
  };
};
