import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { queryKeys } from 'constants/';

import api from 'features/api/chart/strategy';
import {
  BacktestingWithConfig,
  BacktestingAndStrategy,
  PublicBacktestingAndStrategy,
  PublicBacktesting,
} from 'features/schemas/server';

interface StrategyListQueryInterface<T> {
  username?: string;
  options?: UseQueryOptions<T>;
}

const useAllBacktestings = ({
  username,
  options,
}: StrategyListQueryInterface<BacktestingWithConfig[]>) => {
  return useQuery<BacktestingWithConfig[]>(
    [queryKeys.ALL_BACKTESTINGS, { username }],
    async () => await api.readAllBacktestingsAndStrategies(),
    {
      enabled: Boolean(username),
      ...options,
    },
  );
};

interface StrategyDetailQueryInterface<T> {
  backtestingId?: string;
  options?: UseQueryOptions<T>;
}

const useBacktestingAndStrategy = ({
  backtestingId,
}: StrategyDetailQueryInterface<BacktestingAndStrategy>) => {
  return useQuery<BacktestingAndStrategy>(
    [queryKeys.BACKTESTING_AND_STRATEGY, { backtestingId: backtestingId }],
    () => api.readBacktestingAndStrategy(backtestingId),
    {
      enabled: Boolean(backtestingId),
    },
  );
};

const usePublicBacktestingAndStrategy = ({
  backtestingId,
}: StrategyDetailQueryInterface<PublicBacktestingAndStrategy>) => {
  return useQuery<PublicBacktestingAndStrategy>(
    [queryKeys.PUBLIC_BACKTESTING_AND_STRATEGY, { backtestingId }],
    () => api.readPublicBacktestingAndStrategy(backtestingId),
    {
      enabled: Boolean(backtestingId),
    },
  );
};

const useAllPublicBacktestings = ({
  options,
}: {
  options?: UseQueryOptions<PublicBacktesting[]>;
}) => {
  return useQuery<PublicBacktesting[]>(
    [queryKeys.ALL_PUBLIC_BACKTESTINGS],
    () => api.readAllPublicBacktestings(),
    options,
  );
};

export default {
  useAllBacktestings,
  useBacktestingAndStrategy,
  usePublicBacktestingAndStrategy,
  useAllPublicBacktestings,
};
