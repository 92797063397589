import { AxiosResponse } from 'axios';

import { chartAPI } from 'features/api/chart/base';
import {
  BacktestingHistoryResponse,
  BacktestingSummaryAnalysis,
  BacktestingSummaryResponseDTO,
  ReadBacktestingTaskResponse,
  ReadPreviewTaskResponse,
} from 'features/api/chart/schemas';
import {
  BacktestingForm,
  BacktestingPreviewForm,
} from 'features/schemas/server/';

const revokeTask = async (taskId: string): Promise<void> => {
  return await chartAPI.delete('/backtesting', {
    params: { task_id: taskId },
  });
};

const readBacktestingTask = async (
  taskId: string,
): Promise<ReadBacktestingTaskResponse> => {
  const response = await chartAPI.get<ReadBacktestingTaskResponse>(
    '/backtesting',
    {
      params: { task_id: taskId },
    },
  );
  return response.data;
};

const createBacktestingTask = async (
  data: BacktestingForm,
): Promise<string> => {
  // TODO: temp, comment, https://timepercent.atlassian.net/browse/TB-4928
  if (data.strategy.sell_exprs && data.strategy.sell_exprs.length === 0) {
    delete data.strategy.sell_exprs;
  }

  const response = await chartAPI.post<string>('/backtesting', data);
  return response.data;
};

const readBacktestingSummaryByBacktestingId = async (
  backtestingId: string,
): Promise<BacktestingSummaryResponseDTO> => {
  const response = await chartAPI.get<BacktestingSummaryResponseDTO>(
    `/backtesting/${backtestingId}`,
  );
  return response.data || {};
};

const readBacktestingSummaryAnalysisByBacktestingId = async (
  backtestingId: string,
): Promise<BacktestingSummaryAnalysis> => {
  const response = await chartAPI.get<BacktestingSummaryAnalysis>(
    `/backtesting/${backtestingId}/analysis`,
  );
  return response.data;
};

const readBacktestingHistoryByBacktestingId = async (
  backtestingId: string,
): Promise<BacktestingHistoryResponse> => {
  const response = await chartAPI.get<BacktestingHistoryResponse>(
    `/backtesting/${backtestingId}/history`,
  );
  return response.data;
};

const readPreviewTask = async (
  taskId: string,
): Promise<AxiosResponse<ReadPreviewTaskResponse>> => {
  return await chartAPI.get<ReadPreviewTaskResponse>('/backtesting/preview', {
    params: { task_id: taskId },
  });
};

const createBacktestingPreviewTask = async (
  data: BacktestingPreviewForm,
): Promise<string> => {
  const response = await chartAPI.post<string>('/backtesting/preview', data);
  return response.data;
};

export default {
  revokeTask,
  readBacktestingTask,
  createBacktestingTask,
  readBacktestingSummaryByBacktestingId,
  readBacktestingSummaryAnalysisByBacktestingId,
  readBacktestingHistoryByBacktestingId,
  readPreviewTask,
  createBacktestingPreviewTask,
};
