import { useMemo } from 'react';

import { Price } from 'pages/user/[username]/tradingrobot';

import { Tradingrobot, TradingrobotDetail } from 'features/api/chart/schemas';

import { useGetMarketCurrentPrices } from './socket/useGetMarketCurrentPrices';

export const useTradingRobotMetrics = ({
  tradingrobot,
  initialPrices,
}: {
  tradingrobot: Tradingrobot | TradingrobotDetail;
  initialPrices: Price[];
}) => {
  const {
    config,
    real_pnl: realPnl,
    last_started_at: lastStartedAt,
    last_stopped_at: lastStoppedAt,
    markets,
    principal,
  } = tradingrobot;

  const isActive = useMemo(
    () => Boolean(lastStartedAt) && !lastStoppedAt,
    [lastStartedAt, lastStoppedAt],
  );

  const [count, price] = useMemo(
    () =>
      markets && Object.keys(markets).length > 0 && config.markets
        ? markets[config.markets[0]]
        : [0, 0],
    [markets, config.markets],
  );

  let currentPrices = useGetMarketCurrentPrices(
    config.markets.map((m) => m.split('-')[1]),
  );

  // 웹소켓 연결이 안되어 있을 때, 초기 가격으로 계산
  if (Object.keys(currentPrices).length === 0) {
    const findPrice = (currency: string) => {
      return initialPrices.find((price) =>
        // eslint-disable-next-line no-prototype-builtins
        price.hasOwnProperty(currency),
      ) as Record<string, number>;
    };

    currentPrices = findPrice(config.markets[0].replace('KRW-', ''));
  }

  const evalPnl =
    currentPrices[config.markets[0].replace('KRW-', '')] * Number(count) -
    Number(price);

  const pnl = (realPnl || 0) + (evalPnl || 0);
  const ror = (pnl / Number(principal)) * 100;
  const evalNotionalValue = (evalPnl || 0) + Number(price);

  return { isActive, pnl, ror, evalNotionalValue };
};
