import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'constants/';

import api from 'features/api/chart/data';
import { IndicatorInfo } from 'features/api/chart/schemas';

const useAllDataIndicatorInfo = () =>
  useQuery<IndicatorInfo[]>([queryKeys.DATA_INDICATORS], () =>
    api.readAllIndicatorInfo(),
  );

export default {
  useAllDataIndicatorInfo,
};
