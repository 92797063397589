import { useRouter } from 'next/router';

import { PropsWithChildren, useEffect } from 'react';

import { routes } from 'constants/';
import { useResponsive } from 'hooks';
import { useSession } from 'next-auth/react';

import Splash from 'components/Loading/Splash';
import MobileNavigation from 'components/Navigation/MobileNavigation';

import styles from 'components/Layouts/ResponsiveLayout/ResponsiveLayout.module.scss';

interface ResponsiveLayoutProps {
  children: React.ReactElement;
  childrenForMobile?: React.ReactElement;
  childrenForTablet?: React.ReactElement;
}

const ResponsiveLayout = ({
  children,
  childrenForMobile = <Splash />,
  childrenForTablet,
}: ResponsiveLayoutProps): React.ReactElement | null => {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const router = useRouter();
  const navigatationExcludedRoutes = [
    routes.blogRoute,
    routes.backtestingRoute,
    '/user/[username]/strategy/[strategyId]',
    '/user/[username]/tradingrobot/new',
    '/user/[username]/tradingrobot/[robotId]',
    '/marketplace/[strategyId]',
  ];

  if (isMobile) {
    return (
      <div className={styles.mobileRoot}>
        {childrenForMobile}
        {!navigatationExcludedRoutes.includes(router.pathname) && (
          <MobileNavigation />
        )}
      </div>
    );
  }

  if (isTablet) {
    return (
      childrenForTablet || (
        <div className={styles.mobileRoot}>
          {childrenForMobile}
          {router.pathname !== routes.signInRoute && <MobileNavigation />}
        </div>
      )
    );
  }

  if (isDesktop) {
    return children;
  }

  return children;
};

export const UnSupportedLayout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isUnSupported } = useResponsive();
  const router = useRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    if (!isUnSupported) {
      return;
    }

    if (status === 'authenticated') {
      router.push(routes.myStrategyRoute(session.username));
      return;
    }

    router.push('/');
  }, [isUnSupported, router, session, status]);

  if (isUnSupported) {
    return (
      <>
        <div className={styles.unsupport}>
          <div>현재 이 페이지는</div>
          <div>PC 에서만 이용 가능해요 :(</div>
        </div>
        <MobileNavigation />
      </>
    );
  }

  return <>{children}</>;
};

export default ResponsiveLayout;
