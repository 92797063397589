import { useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import { selectMarketModalLowLiquidityFilterTooltip } from 'constants/';
import { useFeatureToggle } from 'hooks';
import ReloadIcon from 'images/icons/svgs/reload.svg';

import { useAppSelector } from 'store';

import marketQuery from 'hooks/useQuery/market';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';
import { Market } from 'features/api/chart/schemas';

import { convertDateToString } from 'features/utils';

import Button from 'components/Button';
import { SortOrder } from 'components/Icon/SortIcon';
import TooltipIcon from 'components/Icon/TooltipIcon';
import SearchBar from 'components/Input/SearchBar';

import Toggle from 'components/Input/Toggle';
import { SelectValueModalProps } from 'components/Modal/ModalTypes';

import SelectModal from 'components/Modal/SelectModal/SelectModal';
import RatelTooltip from 'components/RatelTooltip';

import {
  SelectMarketModalHeaderRow,
  SelectMarketModalMarketRow,
} from 'components/Row/SelectMarketModalRow';

import styles from 'components/Modal/SelectMarketModal/SelectMarketModal.module.scss';
import colors from 'styles/constants/_colors.module.scss';

type SelectMarketModalProps = Omit<SelectValueModalProps<Market[]>, 'onSubmit'>;

const SelectMarketModal = ({ onSelect, onClose }: SelectMarketModalProps) => {
  const {
    data: markets = [],
    refetch,
    dataUpdatedAt,
  } = marketQuery.useAllMarkets();
  const [searchText, setSearchText] = useState('');
  const [transactionAmountSortOrder, setTransactionAmountSortOrder] =
    useState<SortOrder>('Descending');
  const [selectedMarketNames, setSelectedMarketNames] = useState<string[]>([]);
  const initialSelectedMarkets = useAppSelector(
    (state) => state.settings.markets,
  );

  const { isFeatureEnabled: isMarketModalEnabled } = useFeatureToggle({
    flagName: 'REACT_APP_FLAG_MARKET_FILTER',
  });

  useEffect(() => {
    setSelectedMarketNames(initialSelectedMarkets.map((market) => market.name));
  }, [initialSelectedMarkets]);

  const filteredMarkets = useMemo(() => {
    return markets
      .filter(
        (market) =>
          market.name
            .split('-')[1]
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          market.kr_name?.includes(searchText),
      )
      .sort((a, b) => {
        switch (transactionAmountSortOrder) {
          case 'Ascending':
            return a.dollar_volume - b.dollar_volume;
          case 'Descending':
            return b.dollar_volume - a.dollar_volume;
          default:
            return 0;
        }
      });
  }, [markets, searchText, transactionAmountSortOrder]);

  const handleCloseClick = () => {
    trackEvent(amplitudeEventNames.BTN_SELECT_PAIR_MODAL_CLOSE, {
      select_pair_modal_selected: selectedMarketNames,
      select_pair_modal_close_position: 'header',
    });
    onClose();
  };

  const handleSubmitButtonClick = () => {
    trackEvent(amplitudeEventNames.BTN_SELECT_PAIR_MODAL_CLOSE, {
      select_pair_modal_selected: selectedMarketNames,
      select_pair_modal_close_position: 'bottom',
    });

    onSelect(
      markets.filter((market) => selectedMarketNames.includes(market.name)),
    );
  };

  const isValid = useMemo(() => {
    return selectedMarketNames.length > 0;
  }, [selectedMarketNames]);

  const handleMarketSelect = (market: Market) => {
    if (selectedMarketNames.includes(market.name)) {
      setSelectedMarketNames((prev) =>
        prev.filter((prevMarketItem) => prevMarketItem !== market.name),
      );
      return;
    }

    if (selectedMarketNames.length >= 1) {
      setSelectedMarketNames([market.name]);
      return;
    }

    setSelectedMarketNames((prev) => [...prev, market.name]);
  };

  const handleTransactionAmountSortOrderToggle = () => {
    trackEvent(amplitudeEventNames.BTN_SELECT_PAIR_MODAL_REFRESH);
    setTransactionAmountSortOrder((prev) => {
      switch (prev) {
        case 'Ascending':
          return 'Descending';
        case 'Descending':
          return 'Default';
        default:
          return 'Ascending';
      }
    });
  };

  const handleRefreshButtonClick = () => {
    refetch();
  };

  return (
    <SelectModal
      title={'백테스팅할 종목 1개를 선택해 주세요.'}
      onClose={handleCloseClick}
      width={750}
      height={'auto'}
    >
      <div className={styles.root}>
        <div className={styles.lowLiquidityFilterContainer}>
          <span className={styles.lowLiquidityFilterTitle}>
            저유동성 종목 필터
          </span>
          <TooltipIcon id="[저유동성 종목 필터]" />
          <RatelTooltip
            id="[저유동성 종목 필터]"
            title="[저유동성 종목 필터]"
            descriptionTag={
              <>
                <div>{selectMarketModalLowLiquidityFilterTooltip.meaning}</div>
                <br />
                <div>{selectMarketModalLowLiquidityFilterTooltip.info}</div>
              </>
            }
          />
          {isMarketModalEnabled && (
            <div className={styles.switchButtonWrapper}>
              <Toggle
                isSwitchOn={true}
                switchOnColor={colors.p70}
                switchOnHandleColor={colors.white}
              />
            </div>
          )}
        </div>
        <div className={styles.lastUpdateAndSearchBarContainer}>
          <div className={styles.lastUpdateInfoContainer}>
            <span className={styles.lastUpdateText}>마지막 업데이트 :</span>
            <span
              className={clsx(styles.lastUpdateText, styles.lastUpdateTime)}
            >
              {dataUpdatedAt && convertDateToString(new Date(dataUpdatedAt))}
            </span>
            <ReloadIcon onClick={handleRefreshButtonClick} />
          </div>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            placeholder={'검색어를 입력해주세요'}
            width={280}
          />
        </div>
        <div className={styles.rowContainer}>
          <SelectMarketModalHeaderRow
            transactionAmountSortOrder={transactionAmountSortOrder}
            handleTransactionAmountSortOrderToggle={
              handleTransactionAmountSortOrderToggle
            }
          />
          <div className={styles.rowListWrapper}>
            {filteredMarkets.map((market) => {
              return (
                <SelectMarketModalMarketRow
                  key={market.name}
                  market={market}
                  isSelected={selectedMarketNames.includes(market.name)}
                  handleSelect={() => handleMarketSelect(market)}
                  searchText={searchText}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.confirmButtonContainer}>
          <div className={styles.selectedCountWrapper}>
            <span className={styles.selectedCount}>
              {selectedMarketNames.length}
            </span>
            <span>/</span>
            <span>{markets.length}</span>
            <span>개</span>
          </div>
          <Button
            onClick={handleSubmitButtonClick}
            isClickable={isValid}
            theme="primary1"
            size="medium"
          >
            선택 완료
          </Button>
        </div>
      </div>
    </SelectModal>
  );
};

export default SelectMarketModal;
