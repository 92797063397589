import { useRouter } from 'next/router';

import { useState, useEffect, ReactNode } from 'react';

import { queryClient } from 'hooks/useQuery/base';

import Spinner from 'components/Spinner';

import styles from './index.module.scss';

const exceptionPaths = ['/user/[username]/tradingrobot/new'];
const threshold = 100;

interface PullToRefreshProps {
  onRefresh?: () => void;
  children: ReactNode;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  onRefresh = () => {
    queryClient.invalidateQueries({
      predicate: () => true,
    });
    // window.location.reload();
  },
  children,
}) => {
  const router = useRouter();
  const { pathname } = router;
  const [pullStartY, setPullStartY] = useState<number | null>(null);
  const [pullEndY, setPullEndY] = useState<number | null>(null);
  const [isPulling, setIsPulling] = useState(false);

  useEffect(() => {
    if (exceptionPaths.includes(pathname)) {
      return;
    }

    const handleTouchStart = (e: TouchEvent) => {
      if (window.scrollY === 0) {
        setPullStartY(e.touches[0].clientY);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (pullStartY !== null) {
        setPullEndY(e.touches[0].clientY);
      }
    };

    const handleTouchEnd = () => {
      if (
        pullStartY !== null &&
        pullEndY !== null &&
        pullEndY - pullStartY > threshold
      ) {
        setIsPulling(true);
        onRefresh();
      }

      setPullStartY(null);
      setPullEndY(null);

      setTimeout(() => {
        setIsPulling(false);
      }, 1000);
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [pullStartY, pullEndY, isPulling, onRefresh, pathname]);

  return (
    <>
      <div className={styles.root}>
        {isPulling && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
      </div>
      {children}
    </>
  );
};

export default PullToRefresh;
