import { chartAPI } from 'features/api/chart/base';
import { CryptoExchangeKind } from 'features/api/chart/constants';
import { Market } from 'features/api/chart/schemas';

const readMarket = async (
  market: string,
  kind?: CryptoExchangeKind,
): Promise<Market> => {
  const params = kind ? { exchange: kind } : {};
  const response = await chartAPI.get<Market>(`/market/${market}`, {
    params: params,
  });
  return response.data;
};

const readAllMarkets = async (kind?: CryptoExchangeKind): Promise<Market[]> => {
  const params = kind ? { exchange: kind } : {};
  const response = await chartAPI.get<Market[]>('/market/dollar-volume', {
    params,
  });

  return response.data.filter((market) => market.is_active);
};

export default {
  readMarket,
  readAllMarkets,
};
