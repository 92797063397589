import { IndicatorInfo } from 'features/schemas/client';

const IndicatorJson = [
  {
    name: 'candle/open',
    kr_name: '시가',
    en_name: 'Open Pirce',
    description: '캔들 기간 중 시작하는 시점의 가격입니다.',
    category: 'general',
  },
  {
    name: 'candle/high',
    kr_name: '고가',
    en_name: 'High Pirce',
    description: '캔들 기간 중 최고 가격입니다.',
    category: 'general',
  },
  {
    name: 'candle/low',
    kr_name: '저가',
    en_name: 'Low Price',
    description: '캔들 기간 중 최저 가격입니다.',
    category: 'general',
  },
  {
    name: 'candle/close',
    kr_name: '종가',
    en_name: 'Close Pirce',
    description: '캔들 기간 중 끝나는 시점의 가격입니다.',
    category: 'general',
  },
  {
    name: 'candle/volume',
    kr_name: '거래량',
    en_name: 'Volume',
    description: '특정 기간 동안 얼만큼 거래 되었는지를 나타내는 지표입니다.',
    category: 'general',
  },
  {
    name: 'candle/value',
    kr_name: '거래대금',
    en_name: 'Traded Volume',
    description: '일정 시간 동안 거래된 총 거래 금액입니다.',
    category: 'general',
  },
  {
    name: 'adi/adx',
    kr_name: 'ADX',
    en_name: 'ADX',
    description: '평균 방향성 지수(ADX)로 추세 강도를 나타내는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'adi/mdi',
    kr_name: '-DI',
    en_name: '-DI',
    description: '마이너스 방향 지표(-DI)로 추세 강도를 나타내는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'adi/pdi',
    kr_name: '+DI',
    en_name: '+DI',
    description: '플러스 방향 지표(+ DI)로 추세 강도를 나타내는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'bb/lower',
    kr_name: '볼린저 밴드 로우어',
    en_name: 'Bollinger Bands Lower',
    description:
      '주가의 이동평균선과 표준편차를 이용한 볼린저 밴드 범위 중 하위값을 나타내는 지표입니다.',
    category: 'volatility',
  },
  {
    name: 'bb/middle',
    kr_name: '볼린저 밴드 중앙값',
    en_name: 'Bollinger Bands Middle',
    description:
      '주가의 이동평균선과 표준편차를 이용한 볼린저 밴드 범위 중 중간값을 나타내는 지표입니다.',
    category: 'volatility',
  },
  {
    name: 'bb/perb',
    kr_name: '볼린저 밴드 %B',
    en_name: 'Bollinger Bands %B',
    description:
      '주가의 이동평균선과 표준편차를 이용한 밴드폭 안에서 현재 주가의 위치를 나타내는 지표입니다. (단위: %)',
    category: 'volatility',
  },
  {
    name: 'bb/upper',
    kr_name: '볼린저 밴드 어퍼',
    en_name: 'Bollinger Bands Upper',
    description:
      '주가의 이동평균선과 표준편차를 이용한 볼린저 밴드 범위 중 상위값을 나타내는 지표입니다.',
    category: 'volatility',
  },
  {
    name: 'bb/width',
    kr_name: '볼린저 밴드 너비',
    en_name: 'Bollinger Bands Width',
    description:
      '볼린저밴드의 어퍼 밴드와 로우어 밴드 사이 너비를 수량화한 지표입니다.',
    category: 'volatility',
  },
  {
    name: 'cci',
    kr_name: '커모디티 채널 인덱스',
    en_name: 'Commodity Channel Index',
    description:
      '일정 기간(14)의 가격과 이동 평균의 차이를 측정하는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'ichimoku/bl',
    kr_name: '일목 구름 베이스 라인',
    en_name: 'Ichimoku Cloud Base Line',
    description:
      '최근 26개의 캔들의 최고점과 최저점의 평균값을 연결하여 만든 지표입니다',
    category: 'trends',
  },
  {
    name: 'ichimoku/cl',
    kr_name: '일목 구름 컨버젼 라인',
    en_name: 'Ichimoku Cloud Conversion Line',
    description:
      '최근 9개의 캔들의 최고점과 최저점의 평균값을 연결하여 만든 지표입니다.',
    category: 'trends',
  },
  {
    name: 'ichimoku/ls',
    kr_name: '일목 구름 래깅 스팬',
    en_name: 'Ichimoku Cloud Lagging Span',
    description: '현재 가격을 26개의 캔들 뒤로 후행시켜 연결한 지표입니다.',
    category: 'trends',
  },
  {
    name: 'ichimoku/lsa',
    kr_name: '일목 구름 리딩 스팬 A',
    en_name: 'Ichimoku Cloud Leading Span A',
    description:
      '일목 구름 컨버젼 라인과 베이스 라인의 평균값을 26개 캔들 앞으로 선행시켜 연결한 지표입니다.',
    category: 'trends',
  },
  {
    name: 'ichimoku/lsb',
    kr_name: '일목 구름 리딩 스팬 B',
    en_name: 'Ichimoku Cloud Leading Span B',
    description:
      '최근 52개의 캔들의 최고점과 최저점의 평균값을 26개 캔들 앞으로 선행시켜 연결한 지표입니다.',
    category: 'trends',
  },
  {
    name: 'ma',
    kr_name: '이동 평균',
    en_name: 'Moving Average',
    description: '정해진 기간동안 해당 종목의 평균값을 보여주는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'macd/hist',
    kr_name: 'MACD 히스토그램',
    en_name: 'MACD Histogram',
    description:
      '장단기 이동평균선간의 차이를 이용한 MACD와 Signal 사이의 차이를 나타내는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'macd/macd',
    kr_name: 'MACD',
    en_name: 'MACD',
    description:
      '장단기 이동평균선간의 차이를 이용하여 트랜드 방향을 나타내는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'macd/sig',
    kr_name: 'MACD 시그널',
    en_name: 'MACD Signal',
    description:
      '장단기 이동평균선간의 차이를 이용한 MACD를 지수이동평균한 지표입니다.',
    category: 'trends',
  },
  {
    name: 'rsi',
    kr_name: '상대강도지수',
    en_name: 'Relative Strength Index',
    description:
      '일정 기간(14) 주가가 전일 가격에 비해 상승한 변화량과 하락한 변화량을 이용하여 가격 움직임의 속도와 크기를 측정하는 모멘텀 지표입니다.',
    category: 'momentum',
  },
  {
    name: 'vma',
    kr_name: '거래량 이동 평균',
    en_name: 'Volume Moving Average',
    description:
      '일정 기간(20) 해당 종목의 거래량 평균값을 보여주는 지표입니다.',
    category: 'trends',
  },
  {
    name: 'stoch/k',
    kr_name: '스토캐스틱 %K',
    en_name: 'Stochastic %K',
    description:
      '일정 기간(14)의 가격 변동폭과 현재의 가격과의 관계를 백분율로 나타내는 지표입니다.',
    category: 'momentum',
  },
  {
    name: 'stoch/d',
    kr_name: '스토캐스틱 %D',
    en_name: 'Stochastic %D',
    description: '스토캐스틱 %K 의 이동평균(3) 값을 나타내는 지표입니다.',
    category: 'momentum',
  },
  {
    name: 'pvo',
    kr_name: '볼륨 오실레이터',
    en_name: 'Volume Oscillator',
    description:
      '거래량 이동 평균(5)과 거래량 이동 평균(10)과의 관계를 이용하여 가격 추세의 강약을 나타내는 지표입니다.',
    category: 'momentum',
  },
  {
    name: 'mfi',
    kr_name: '머니 플로우 인덱스',
    en_name: 'Money Flow Index',
    description:
      '일정 기간(14)의 가격과 거래량을 분석하여 매수 및 매도 압력을 측정하는 지표입니다',
    category: 'momentum',
  },
  {
    name: 'cmf',
    kr_name: '체이킨 머니 플로우',
    en_name: 'Chaikin Money Flow',
    description: '일정 기간(20)의 머니 플로우 볼륨을 측정하는 지표입니다',
    category: 'momentum',
  },
  {
    name: 'fisher/fisher',
    kr_name: '피셔 트랜스폼',
    en_name: 'Fisher Transform',
    description:
      '일정 기간(14)의 가격을 가우스 정규 분포로 변환하여 가격 변동 및 추세 변화를 나타내는 지표입니다',
    category: 'momentum',
  },
  {
    name: 'fisher/trigger',
    kr_name: '피셔 트랜스폼 트리거',
    en_name: 'Fisher Transform Trigger',
    description:
      '일정 기간(14)의 가격을 가우스 정규 분포로 변환하여 가격 변동 및 추세 변화를 나타내는 지표입니다',
    category: 'momentum',
  },
  {
    name: 'vroc',
    kr_name: '볼륨 레이트 오브 체인지',
    en_name: 'Volume Rate of Change',
    description: '일정 기간(14)의 거래량 변동률을 나타내는 지표입니다',
    category: 'momentum',
  },
  {
    name: 'mktcap',
    kr_name: '비트코인 마캣캡',
    en_name: 'BTC Market Cap',
    description: '비트코인의 시가총액을 나타내는 지표입니다',
    category: 'onchain_data',
  },
];

export const validateIndicatorNameToInfo = (name: string): IndicatorInfo => {
  const result = IndicatorJson.find((indicator) => indicator.name === name);
  if (!result) {
    throw new Error(`Indicator not found for name: ${name}`);
  }
  return result as IndicatorInfo;
};
