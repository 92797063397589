import { createSlice } from '@reduxjs/toolkit';

export interface ChartData {
  symbol: string;
  unit: string;
  selectedData: SelectedData;
  baseDate: string;
  visibleRange: {
    from: number;
    to: number;
  } | null;
  targetVisibleRange: {
    from: number;
    to: number;
  } | null;
}

export interface SelectedData {
  label: string;
  time: number;
  value: number;
}

export const initialChartData: ChartData = {
  symbol: 'COINONE:BTC/KRW',
  unit: '1D',
  selectedData: {
    label: '',
    time: 0,
    value: 0,
  },
  baseDate: '',
  visibleRange: null,
  targetVisibleRange: null,
};

export const chartDataSlice = createSlice({
  name: 'strategy',
  initialState: initialChartData,
  reducers: {
    updateChartData(state, action) {
      const d = action.payload;
      if (d.selectedData.label !== 'label') {
        state.selectedData = d.selectedData;
        if (d.baseDate) {
          state.baseDate = d.baseDate;
        } else {
          state.baseDate = '';
        }
      }
    },
    updateBaseDate(state, action) {
      const d = action.payload;
      state.baseDate = d.baseDate;
    },
    updateSymbolUnit(state, action) {
      const d = action.payload;
      state.symbol = d.symbol;
      state.unit = d.unit;
    },
    updateVisibleRange(state, action) {
      const d = action.payload;
      state.visibleRange = d.visibleRange;
    },
    updateTargetVisibleRange(state, action) {
      const d = action.payload;
      state.targetVisibleRange = d.targetVisibleRange;
    },
  },
});

export const {
  updateChartData,
  updateSymbolUnit,
  updateVisibleRange,
  updateTargetVisibleRange,
} = chartDataSlice.actions;
