import { useAppDispatch, useAppSelector } from 'store';

import { FlagKind } from 'features/env';
import { toggleProduction } from 'features/slices';
type Prefix<P extends string, S extends string> = `${P}${S}`;
type ReactFlagKind = Prefix<'REACT_APP_', FlagKind>;
interface FeatureToggleProps {
  flagName?: ReactFlagKind;
}

export const useFeatureToggle = ({ flagName }: FeatureToggleProps) => {
  const dispatch = useAppDispatch();
  const { isProduction, featureEnv } = useAppSelector(
    (state) => state.featureToggle,
  );
  const toggleIsProduction = () => {
    dispatch(toggleProduction());
  };

  const isFeatureEnabled = flagName
    ? Boolean(Number(featureEnv[flagName]))
    : false;

  return {
    isFeatureEnabled,
    isProduction,
    toggleIsProduction,
  };
};
