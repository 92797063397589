import { useEffect } from 'react';

import { Market } from 'features/api/chart/schemas';

import { useStrategyPageModal } from 'components/Modals/StrategyPageModals';

/*
 * Return the selected market value with syncing that of redux-state.
 */
export const useSelectMarkets = (
  callback: (market: Market[]) => void,
): Market[] | undefined => {
  const { context } = useStrategyPageModal();

  useEffect(() => {
    if (context.selectedMarkets) {
      callback(context.selectedMarkets);
    }
  }, [callback, context.selectedMarkets]);

  return context.selectedMarkets;
};
